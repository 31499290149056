html,
body {
	font-size: 16px;
	color: #3a3c41;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	line-height: 1.3;
}

a:hover,
a:focus {
	color: #de1212;
	cursor: pointer;
}

p {
	font-size: 1rem;
	margin-top: 0;
	margin-bottom: 1rem;
}

blockquote {
	background: #f3f8fa;
	border-left-color: #86d0e4;
	font-style: italic;
	font-size: 16px;
	line-height: 26px;
	font-family: 'Noto Serif', serif;
}

pre {
	padding: 15px;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

td img {
	max-width: none;
}

form p {
	margin-bottom: 1rem;
}

form fieldset label {
	border-bottom: 0;
	padding: 0;
}

table {
	background: transparent;
	border: none;
}

table thead {
	background-color: transparent;
}

table tbody tr,
table tbody td {
	border: none;
}

/*styled tables*/
table.styled {
	width: 100%;
	margin: 10px 0 10px 0;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #ccc;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #1e8199;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #61afc1;
	color: black;
}

table.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0 none;
}

table.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #c5e9f2;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

@media (max-width: 39.9375em) {
	table.styled.tablesaw {
		border-bottom: 1px solid grey;
		border-left: 1px solid grey;
	}

	table.styled.tablesaw tbody {
		border-top: 1px solid grey;
	}

	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 1px solid grey;
		border-bottom: 0 none;
	}

	table.styled.tablesaw tr th:last-child,
	table.styled.tablesaw tr td:last-child {
		border-right: 1px solid grey;
	}

	.tablesaw-stack tbody tr {
		border-bottom: 0 none;
	}
}

.label {
	color: #3a3c41;
	font-weight: 500;
	font-size: 16px;
}

/* breakpoints */
[data-breakpoints] {
	display: none;
}

@media screen and (max-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block;
	}
}

@media screen and (max-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block;
	}
}

@media screen and (max-width: 479px) {
	[data-breakpoints*="xs,sm"] {
		display: block;
	}
}

/* buttons */
.home-features .features-intro a,
.btn,
.read-more {
	transition: all 300ms ease;
}

.primary,
.secondary,
.tertiary,
#main-footer .widget input[type="submit"],
form input[type="submit"],
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type="submit"] {
	font-size: 14px;
	font-weight: 700;
	background-color: #1e7f94;
	padding: 10px 20px;
	color: #fff;
	text-decoration: none;
	display: inline-block;
	letter-spacing: 1px;
	transition: all 300ms ease;
	border-radius: 0;
}

.primary:hover,
.secondary:hover,
.tertiary:hover,
#main-footer .widget input[type="submit"]:hover,
form input[type="submit"]:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type="submit"]:hover {
	cursor: pointer;
	background-color: #de1212;
	color: #fff;
}

a.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button,
button.secondary {
	cursor: pointer;
	background-color: #de1212;
	color: #fff;
}

a.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover,
button.secondary:hover {
	background-color: #3a3c41;
	color: #fff;
}

a.tertiary,
button.tertiary {
	cursor: pointer;
	background-color: #919292;
	color: #fff;
}

a.tertiary:hover,
button.tertiary:hover {
	cursor: pointer;
	background-color: #de1212;
	color: #fff;
}

#printHeader,
#printFooter {
	display: none;
}

/*skip to main content WCAG link*/
.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	height: 0px;
}

.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.375rem 1rem !important;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	color: #fff;
}


/* general inputs */
form input[type="text"],
form input[type="email"],
form input[type="search"],
form input[type="url"],
form input[type="tel"],
form input[type="number"],
form input[type="date"],
form input[type="range"],
form input[type="password"],
form textarea,
select {
	padding: 10px 8px;
	border: 1px solid #ccc;
	color: #333;
	margin-bottom: 0;
	height: 100%;
}


.main-content-wrap a {
	border-bottom: 1px solid rgba(58, 60, 65, 0.5);
	transition: all 200ms ease;
}

.main-content-wrap a.primary,
.main-content-wrap a.secondary,
.main-content-wrap a.tertiary {
	border-bottom: 0;
}

.main-content-wrap a:hover {
	border-bottom-color: transparent;
}

/***********************************
************************************
	HEADER
************************************
************************************/
.header-content {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.header-top p {
	margin-bottom: 0;
	font-size: 0.875rem;
	color: #e1e2e5;
}

.header-top p span {
	white-space: unset;
}

.header-top a {
	color: white;
	border-bottom: 1px solid white;
	transition: all 200ms ease;
}

.header-top a:hover {
	border-bottom-color: transparent;
}

.visible-479,
.visible-767 {
	display: none;
}

nav.main-menu ul > li:hover > a,
nav.main-menu ul > .current-menu-item > a {
	background-color: #1e7f94;
	color: #fff;
	cursor: pointer;
}


nav.main-menu ul > li ul {
	top: 0;
	background-color: #1e7f94;
}

nav.main-menu ul > li ul li {
	border-bottom: 1px solid #549cad;
}

nav.main-menu ul > li ul li:hover a {
	background-color: #5faabb;
}

nav.main-menu ul > li > a {
	text-transform: capitalize;
}

.nav-drop-list {
	opacity: 0;
	height: 0;
	transition: opacity 300ms ease;
	position: relative;
	z-index: 9999;
}

.open .nav-drop-list {
	opacity: 1;
	height: auto;
}

ul.sub-menu {
	display: none;
}

.open ul.sub-menu {
	display: block;
}

.menu-button {
	display: none;
	float: right;
}

.menu-icon {
	background-color: #1e7f94;
	width: 50px;
	padding: 12px;
	cursor: pointer;
}

#main-footer {
	color: #a4a8b2;
}

.footer-phone-link {
	display: none;
}

.footer-phone-text {
	display: inline-block;
}

.footer-bottom .footer-social-nav {
	margin: 0 20px 0 0;
}

.footer-bottom p {
	color: #a4a5ad;
}

@media (max-width: 991px) {
	nav.navigation-menu.desktop-navigation-menu {
		display: none;
	}

	.menu-button {
		display: block;
	}

	.footer-phone-link {
		display: inline-block;
	}

	.footer-phone-text {
		display: none;
	}
}

@media (max-width: 767px) {
	.footer-bottom .footer-social-nav {
		margin-right: 0;
		margin-bottom: 20px;
	}

	.visible-767 {
		display: block;
	}
}

@media (max-width: 479px) {
	.header-content {
		display: block;
	}

	.menu-button {
		width: 100%;
		background-color: #5faabb;
	}

	.menu-icon {
		float: right;
	}

	.visible-479 {
		display: block;
	}
}

/***********************************
************************************
	mmenu
************************************
************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		background: #0c1923;
	}

	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktopnavigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25em 1.375rem;
		border-bottom: 1px solid #383838;
		text-transform: capitalize;
		float: none;
		box-shadow: none;
		text-transform: uppercase;
	}

	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: transparent;
		cursor: pointer;
	}

	.mm-menu .active {
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.mm-menu a.w--current {
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.mm-menu a:hover {
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: #383838;
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: hsla(0, 0%, 100%, 0.75);
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: rgba(255, 255, 255, 0.1);
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid #383838;
		padding: 0 10px 0 40px;
		height: 61px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 61px;
		border-bottom: 0 none !important;
	}

	.mm-menu a.mm-navbar__title {
		font-size: 1.4rem;
		line-height: 1.8rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: uppercase;
		border-bottom: 0 none;
		padding: 1rem 0;
	}

	.mm-navbar:hover {
		cursor: pointer;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #fff;
		cursor: pointer;
	}
}

/***********************************
************************************
	Slideshow
************************************
************************************/
.home-slider {
	overflow: hidden;
	height: 72vh;
	margin-top: 144px;
}

.home-slider .content-wrapper,
.grid-container .content-box.slideshow .content-wrapper {
	/* display: block;
	top: 50%;
	transform: translateY(-50%);
	overflow: hidden; */
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	overflow: hidden;
}

.home-slider .slide-content,
.grid-container .content-box.slideshow .slide-content {
	position: relative;
	display: block;
	background-color: rgba(255, 255, 255, 0.5);
	padding: 2rem;
}

.cycle-slideshow {
	overflow: hidden;
	display: block;
	height: 100%;
}

.slide {
	height: 100%;
	width: 100%;
	overflow: hidden;
	opacity: 0;
}

.slider-header {
	font-size: 36px;
	margin: 0 0 20px;
	font-weight: 400;
	color: #3a3c41;
	font-family: inherit;
	line-height: 1.1;
	display: block;
}

.home-slider .slide-content p,
.grid-container .content-box.slideshow .slide-content p {
	display: block;
}

.home-slider .slide-content .btn,
.grid-container .content-box.slideshow .slide-content .btn {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.slider-navigation {
	position: absolute;
	z-index: 9000;
	top: auto;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	padding-top: 10px;
	height: 40px;
	text-align: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	padding-top: 10px;
	padding-right: 20px;
	padding-left: 20px;
	border-bottom: 2px none #ccc;
	font-size: 0.8rem;
	text-align: center;
}

.slider-dot {
	position: relative;
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	background-color: #de1212;
	cursor: pointer;
	border-radius: 50%;
	opacity: 0.55;
	margin: 0 3px 0.5em;
	-webkit-transition: background-color 100ms, color 100ms;
	-moz-transition: background-color 100ms, color 100ms;
	-o-transition: background-color 100ms, color 100ms;
	transition: background-color 100ms, color 100ms;
}

.slider-dot.active {
	opacity: 1;
}

.slider-arrow-link {
	font-size: 0;
	text-decoration: none;
	display: block;
	width: 40px;
	height: 40px;
	margin: -20px 0 0;
	position: absolute;
	top: 50%;
	z-index: 9999;
	overflow: hidden;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.8);
	text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
	width: 53px;
	height: 53px;
	margin: -27px 0 0;
	border-radius: 50%;
}

.slider-arrow-link.left {
	left: -100px;
	background: url('/20181129151801/assets/images/slider-left-arrow.png') 17px center no-repeat rgba(222, 18, 18, 0.7);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.slider-arrow-link.right {
	background: url('/20181129151801/assets/images/slider-right-arrow.png') 21px center no-repeat rgba(222, 18, 18, 0.7);
	right: -100px;
	text-align: right;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.home-slider:hover .slider-arrow-link.left,
.grid-container .content-box.slideshow:hover .slider-arrow-link.left {
	left: 20px;
}

.home-slider:hover .slider-arrow-link.right,
.grid-container .content-box.slideshow:hover .slider-arrow-link.right {
	right: 20px;
}

.gallery-post-single {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	height: 100%;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	position: relative;
	background-color: #f0f5f7;
}

@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {

	.home-slider .slide,
	.grid-container .content-box.slideshow .slide {
		height: 100%;
	}

	.home-slider .gallery-post-single,
	.grid-container .content-box.slideshow .gallery-post-single {
		width: 100%;
	}
}

@media (min-width: 1200px) {
	.slider-header {
		font-size: 48px;
	}
}

@media (max-width: 991px) {
	.home-slider {
		height: 53vh;
		margin-top: 180px;
	}

	.slider-header {
		font-size: 30px;
	}
}

@media (max-width: 767px) and (-ms-high-contrast: active),
	(max-width: 767px) and (-ms-high-contrast: none) {

	.home-slider .slide,
	.grid-container .content-box.slideshow .slide {
		height: auto;
	}
}

@media (max-width: 767px) {
	.home-slider {
		height: 100%;
		margin-top: 204px;
	}

	.slide {
		height: auto;
	}

	.home-slider .content-wrapper,
	.grid-container .content-box.slideshow .content-wrapper {
		position: static;
		transform: none;
		top: 0;
	}

	.home-slider .slide-content,
	.grid-container .content-box.slideshow .content-wrapper .slide-content {
		width: 100%;
		padding: 20px 20px 40px 20px;
	}

	.home-slider .slide-content p,
	.grid-container .content-box.slideshow .content-wrapper .slide-content p {
		display: block;
	}

	.slider-arrow-link {
		top: 35%;
	}

	.gallery-post-single {
		height: 300px;
	}
}

@media (max-width: 479px) {
	.home-slider {
		margin-top: 261px;
	}

	.slider-header {
		font-size: 24px;
	}

	.slider-arrow-link {
		top: 20%;
	}

	.gallery-post-single {
		height: 250px;
	}
}

/***********************************
************************************
	HOMEPAGE CONTENT BOXES
************************************
************************************/
h2.home-features-title {
	font-weight: 700;
}

/* services */
@media (max-width: 767px) {
	.home-features .single-feature .icon-wrapper img {
		height: 60px;
	}
}

/* doctors */
.overlay {
	transition: all 300ms ease;
}

/* blog / posts */
.posts-collection.homepage-contentbox.article-items {
	display: grid;
	flex-wrap: wrap;
	margin-bottom: 50px;
	grid-template-columns: 1fr 1fr 1fr;
	text-align: center;
	grid-column-gap: 20px;
}

.posts-collection.homepage-contentbox.article-items .article {
	padding-left: 0;
	padding-right: 0;
	width: 100% !important;
	text-align: center;
	text-decoration: none;
	background-color: #f0f5f7;
}

.posts-collection.homepage-contentbox.article-items .article-image-link {
	display: flex;
}

.posts-collection.homepage-contentbox.article-items .article-image {
	height: 100%;
}

.article-content {
	padding: 20px;
}

h3.article-title {
	margin-top: 0;
}

a.article-title-link {
	font-size: 18px;
	font-weight: 700;
	color: #3a3c41;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	margin: 0 0 30px;
	font-style: italic;
	color: #647078;
	position: relative;
	font-size: 14px;
	background-image: none;
}

.posts-collection.homepage-contentbox.article-items .article-date:after {
	content: '';
	height: 11px;
	position: absolute;
	width: 100%;
	background: rgba(0, 0, 0, 0) url('/20181212114400/assets/images/feature-border.png') center center no-repeat;
	border: none;
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
}

a.posts-homepage-block.button.primary {
	font-size: 14px;
	font-weight: 700;
	background-color: #1e7f94;
	padding: 10px 20px;
	color: #fff;
	text-decoration: none;
	display: inline-block;
	letter-spacing: 1px;
	transition: all 300ms ease;
}

a.posts-homepage-block.button.primary:hover {
	background-color: #de1212;
	color: #fff;
}

@media (max-width: 991px) {
	.posts-collection.homepage-contentbox.article-items {
		grid-template-columns: 1fr 1fr;
	}

	.posts-collection.homepage-contentbox.article-items {
		grid-template-columns: 1fr 1fr;
	}

	.posts-collection.homepage-contentbox.article-items .article {
		display: flex;
		flex-direction: column;
	}

	.posts-collection.homepage-contentbox.article-items .article-content {
		width: 100%;
	}

	.posts-collection.homepage-contentbox.article-items .article-image-link {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.posts-collection.homepage-contentbox.article-items {
		grid-template-columns: 1fr;
		margin-bottom: 0;
	}

	.posts-collection.homepage-contentbox.article-items .article {
		max-width: none;
	}

	.posts-collection.homepage-contentbox.article-items .article-content {
		padding: 20px;
	}

	.posts-collection.homepage-contentbox.article-items .article-title {
		margin-top: 0;
	}

	.posts-collection.homepage-contentbox.article-items .article-image-link {
		display: block;
		height: auto;
	}
}

@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {
	.posts-collection.homepage-contentbox.article-items {
		display: block;
	}

	.posts-collection.homepage-contentbox.article-items:before,
	.posts-collection.homepage-contentbox.article-items:after {
		content: " ";
		display: table;
	}

	.posts-collection.homepage-contentbox.article-items:after {
		clear: both;
	}

	.posts-collection.homepage-contentbox.article-items .article {
		float: left;
		width: 31% !important;
		margin-right: 20px;
	}

	a.posts-homepage-block.button.primary {
		margin-top: 50px;
	}

	#testimonials_module_contentbox {
		overflow: hidden;
	}
}

@media (max-width: 991px) and (-ms-high-contrast: active),
	(max-width: 991px) and (-ms-high-contrast: none) {
	.posts-collection.homepage-contentbox.article-items .article {
		width: 47% !important;
	}
}

@media (max-width: 767px) and (-ms-high-contrast: active),
	(max-width: 767px) and (-ms-high-contrast: none) {
	.posts-collection.homepage-contentbox.article-items .article {
		width: 100% !important;
		margin-right: 0;
	}
}

/* testimonials */
div#testimonials_module_contentbox .description-box {
	margin: 0 0 50px;
}

div#testimonials_module_contentbox .description-box span.description {
	font-family: serif;
	font-style: italic;
	font-size: 1.5rem;
	line-height: 1.4;
	font-weight: 400;
	color: #6a7177;
}

#testimonials_module_contentbox.contentbox_item .author-name {
	position: relative;
	margin: 0 0 30px;
	font-size: 24px;
	font-weight: 700;
	color: #3a3c41;
}

#testimonials_module_contentbox.contentbox_item .author-name:after {
	content: '';
	height: 11px;
	position: absolute;
	width: 100%;
	background: rgba(0, 0, 0, 0) url('/20181212114400/assets/images/feature-border.png') center center no-repeat;
	border: none;
	bottom: -17px;
	left: 50%;
	transform: translateX(-50%);
}

#testimonials_module_contentbox.contentbox_item .company {
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	line-height: 24px;
}

/***********************************
************************************
	FOOTER
************************************
************************************/
.footer-bottom .social-media {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

footer.signoff.grip-logo {
	margin-left: 20px;
}

div#GRIPFooterLogo:hover {
	opacity: 1;
}

div#GRIPFooterLogo {
	padding-top: 0 !important;
	opacity: 0.2;
	transition: all 300ms ease;
}

span#GRIPFooterLogoText {
	line-height: 1;
}

.footer-bottom .footer-social-nav li a {
	opacity: 0.15;
	font-size: 23px;
	font-weight: 700;
}

.footer-bottom .footer-social-nav li a:hover {
	opacity: 1;
	transform: scale(1.11);
}

.copyright h1 {
	color: #a4a8b2;
	line-height: 24px;
	font-size: 16px;
	display: inline-block;
	font-weight: 400;
	margin: 0;
	padding: 0;
	text-transform: none;
}

/* footer content region */
.cms-website-content-region h3 {
	margin: 0 0 25px;
	margin-bottom: 30px;
	color: #f0f5f7;
	font-size: 18px;
	font-weight: 700;
}

/* footer navigation */
.footer-navigation {
	padding: 0;
	margin: 0;
	border-top: 1px solid #4a4c52;
}

.footer-link-wrap {
	list-style: none;
	color: #999ca5;
	border-bottom: 1px solid #4a4c52;
	padding: 0 0 10px;
	margin: 10px 0 0;
	text-transform: capitalize;
}

@media (max-width: 767px) {
	.footer-bottom .social-media {
		justify-content: center;
		flex-direction: column;
		margin-top: 20px;
	}

	footer.signoff.grip-logo {
		margin: 25px 0 0 0;
	}
}

/***********************************
************************************
	INSIDE PAGES
************************************
************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

.container.inside-page-container {
	margin-bottom: 2rem;
	margin-top: 2rem;
}


/*messages*/
#session-message-wrapper .success {
	background-color: #adebb0;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .error {
	background-color: #f5dadd;
	text-align: left;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #333;
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	font-weight: 400;
	font-size: 1.125rem;
	color: #333;
}

.success *:last-child,
.error *:last-child {
	margin-bottom: 0;
}

/* left navigation */
.left-navigation {
	display: inline-block;
	width: 21%;
}

.left-navigation h2 {
	margin: 0;
}

li.left-nav-list-item.half-margin-width {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
}

a.left-nav-section-title-link {
	text-decoration: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0);
	display: block;
	padding: 1.25rem 1rem;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	color: #3a3c41;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 600;
	text-transform: uppercase;
}

.left-navigation nav {
	border-top: 5px solid #de1212;
	background-color: #fff;
	padding-bottom: 1rem;
	margin-bottom: 2.5rem;
}

.left-nav-link-level-1,
.left-nav-link-level-2,
.left-nav-link-level-3 {
	display: block;
	padding: 0.5rem;
	font-weight: 700;
}

li.left-nav-list-item {
	margin-right: 1rem;
	margin-left: 1rem;
}

.left-nav-link-level-1.selected,
.left-nav-link-level-2.selected,
.left-nav-link-level-3.selected,
.left-nav-link-level-1:hover {
	cursor: pointer;
	color: #de1212;
}

.main-content-wrap.with-left-sidebar,
.main-content-wrap.both-sidebars {
	width: 74%;
	float: right;
}

.main-content-wrap.both-sidebars .styles {
	width: 66%;
	float: left;
}

.main-content-wrap.full-width {
	width: 100%;
}

.main-content-wrap.with-right-sidebar .styles {
	width: 74%;
	float: left;
}

@media (max-width: 991px) {

	.main-content-wrap,
	.main-content-wrap.both-sidebars,
	.main-content-wrap.both-sidebars .styles,
	.main-content-wrap.with-left-sidebar,
	.main-content-wrap.with-right-sidebar .styles {
		width: 100%;
	}
}

/* sidebars */
.right-sidebar {
	width: 29%;
	float: right;
}

.main-content-wrap.with-right-sidebar .right-sidebar {
	width: 22%;
}

.right-sidebar .box,
.left-sidebar .box,
.right-sidebar-item-wrap {
	margin-bottom: 2.5rem;
}

.right-sidebar .box h1,
.right-sidebar .box h2,
.right-sidebar .box h3,
.right-sidebar .box h4,
.right-sidebar .box h5,
.left-sidebar .box h1,
.left-sidebar .box h2,
.left-sidebar .box h3,
.left-sidebar .box h4,
.left-sidebar .box h5,
.right-sidebar-heading {
	margin: 0 0 1.25rem 0;
	color: #3a3c41;
	font-size: 1.125rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.right-sidebar .box td,
.right-sidebar .box p,
.left-sidebar .box td,
.left-sidebar .box p,
.right-sidebar-paragraph {
	color: #707070;
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.left-sidebar .box a,
.right-sidebar .box a {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar .box.filter .filter-row .select2-container-multi .select2-choices,
.right-sidebar .box.filter .filter-row .select2-container .select2-choice {
	background: #fff;
}

.right-sidebar .box.filter .filter-row.ui-form-buttons .ui-form-field {
	margin-right: 10px;
}

@media (max-width: 991px) {

	.main-content-wrap.with-right-sidebar .right-sidebar,
	.right-sidebar {
		width: 100%;
		margin-top: 2rem;
	}
}

/* page banner */
.banner {
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	margin-top: 144px;
}

@media (max-width: 1316px) {
	.banner {
		min-height: 216px;
	}
}

@media (max-width: 991px) {
	.banner {
		margin-top: 180px;
		min-height: 164px;
	}
}

@media (max-width: 767px) {
	.banner {
		margin-top: 204px;
		min-height: 127px;
	}
}

@media (max-width: 479px) {
	.banner {
		margin-top: 261px;
		min-height: 79px;
	}
}

/* page title */
.page-top-content {
	display: flex;
	align-items: center;
}

@media (max-width: 991px) {
	.page-top-content {
		flex-direction: column;
	}
}

/* breadcrumbs */
nav.breadcrumbs {
	margin-bottom: 1.4rem;
	margin-top: 1.5rem;
}

.bread-crumb .breadcrumb li.active {
	color: #de1212;
}

ol.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0;
	overflow: hidden;
}

li.breadcrumbs-list-item {
	display: inline-block;
}

.breadcrumb-divider,
.breadcrumb-link {
	margin-right: 0.45rem;
	font-size: 0.875rem;
	display: inline-block;
	float: left;
}

.breadcrumb-divider {
	color: #696969;
}

a.breadcrumb-link {
	border-bottom: 1px solid rgba(116, 116, 116, 0.5);
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	color: #696969;
}

.breadcrumb-link.w--current,
.breadcrumb-link:hover {
	border-bottom-color: rgba(0, 0, 0, 0);
	color: #333;
}

ol.breadcrumbs-list li.breadcrumbs-list-item:last-of-type a {
	border-bottom-color: rgba(0, 0, 0, 0);
	color: inherit;
}

.bread-crumb a:hover {
	text-decoration: none;
	border-bottom-color: transparent;
}

@media (max-width: 991px) {
	.main-content-wrap.with-left-sidebar {
		width: 100%;
		padding: 0;
	}
}

/***********************************
************************************
	MODULES
************************************
************************************/
/* ui-tags */
.ui-form-legend {
	height: 100%;
	background: #f0f5f7;
}

.ui-form-container .ui-form-legend {
	color: #3a3c41 !important;
	background: #f0f5f7;
	line-height: 1.3;
}

.ui-form-legend span.icon {
	display: none;
}

.ui-form-panel {
	padding: 0 !important;
}

.ui-form-label {
	position: relative;
	height: 100%;
}

.ui-form-field {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.ui-form-buttons {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.ui-form-buttons button {
	margin-right: 10px;
}

.ui-form-buttons .ui-form-field {
	width: auto;
}

.ui-form-input.ui-form-button {
	min-height: 0;
	overflow: visible;
}

.ui-form-field em,
em.required_information {
	color: #e00000;
	font-family: sans-serif;
}

@media (max-width: 479px) {
	.ui-form-field {
		overflow: visible;
	}

	.ui-form-buttons button {
		margin-bottom: 0;
	}
}


/* Pagination */
.pagination-wrapper span {
	color: #6e6e6e !important;
}

/* Alerts */
div#alerts_module_subscribe_form form#SubscribeForm td.label {
	padding: 0;
}

#alerts_module_subscribe_form.form .form_item .field input {
	margin-bottom: 0;
}

#alerts_module_subscribe_form.form .form_item p.instructions {
	margin-bottom: 1rem;
}

#alerts_module_subscribe_form.form .item_divider {
	height: 0 !important;
	margin: 0 0 2.5rem 0 !important;
}

#alerts_module_subscribe_form h3 {
	color: #3a3c41;
	margin: 0 0 1.25rem 0;
}

/* Collections */
.module-collections.controller-pages-collections .collection-wrapper h2 {
	font-size: 1.5rem;
}

/* Events */
.primary.event-registration-button {
	border-bottom: 0;
}

/* Forums */
#forums_module h2 {
	color: #3a3c41;
	margin-top: 0px;
}

#forums_module .header-top {
	background: #f0f5f7;
}

#forums_module .back_to a,
#forums_module .header-right a {
	color: #3a3c41;
	border-bottom: 1px solid rgba(58, 60, 65, 0.5);
}

#forums_module .back_to a:hover,
#forums_module .header-right a:hover {
	color: #de1212;
	border-bottom-color: transparent;
}

#forums_module #posts table td.actions a {
	color: #999;
	display: block;
	float: right;
	height: 16px;
	margin-left: 8px;
}

#forums_module #posts table td.actions a {
	color: #6e6e6e;
	cursor: pointer;
}

#forums_module #posts table td.actions a:hover {
	color: #de1212;
	cursor: pointer;
}

#forums_module #topics th {
	height: 25px;
	padding: 10px;
	border-bottom: 1px solid #ccc;
	vertical-align: top;
	text-align: left;
}

#forums_module .pagination .visible {
	margin: 0;
	padding: 0;
	background: none;
	text-align: left;
}

#forums_module table thead tr th:last-child,
#forums_module table thead tr th:first-child {
	border-right: 0;
	border-left: 0;
}

#forums_module #forum-search #forum-search-container input[type=submit].button {
	border: 0;
}

#forums_module form input[type="file"] {
	display: inline;
	margin-bottom: 0;
	width: 100%;
}

.pagination a:hover,
.pagination span:hover {
	color: #3a3c41;
}

#forums_module #posts table,
#forums_module #boards table,
#forums_module #topics table,
#forums_module #posts .post_footer {
	background-color: #f0f5f7;
	border: 0px;
}

@media (max-width: 991px) {

	#forums_module #boards th,
	#forums_module #topics th,
	#forums_module #posts th {
		display: block;
		width: 100%;
		height: auto;
	}

	#forums_module .pagination .visible {
		text-align: center;
	}

	#forums_module #topics tr.moderator_actions td {
		padding-bottom: 1.5rem;
	}
}

/* faqs */
#faqs_module.cms_list .cms_list_item .cms_title h3,
#faqs_module.cms_list .cms_list_subitem .cms_title h3 {
	line-height: 1.3;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0;
}

/* glossary */

/* jobs */
.right-sidebar .box #frmDepartments #Department {
	margin-bottom: 1rem;
}

.box .contentbox_item,
.box.documents .content > table {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	border: 0;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	vertical-align: middle;
	padding-top: 0;
}

#jobs_module.cms_form label {
	margin-bottom: 0;
}

/* locations */

/* news */

/* partners */

/* photo albums */
#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide {
	background-color: #f0f5f7;
}

/* polls */
#polls table td {
	padding: 0 0 0 20px;
}

#polls .session_message.error p {
	margin-bottom: 10px;
}

#polls .ui-form-container .ui-form-legend {
	height: 100%;
}

/* posts */
.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button:hover svg path {
	fill: #fff !important;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	color: #3a3c41 !important;
}

.posts-node #post-comments .post-comments-title {
	background-color: #de1212;
}

.posts-node .posts-comments #post-add-comment .ui-form-buttons {
	padding: 0;
}

.posts-add-comment .ui-form-label {
	position: static;
	padding: 0px;
	height: auto;
	display: block;
	width: 100%;
	left: 0px;
	text-align: left;
	top: auto;
	line-height: 1rem;
}

.posts-add-comment .ui-form-input {
	width: 100%;
}

.ui-form-select > select,
.ui-form-textarea > textarea,
.ui-form-text > input[ type="password"],
.ui-form-text > input[ type="text"] {
	max-width: none;
}

/* products (not ecommerce) */

/* rates */

/* resources */

/* rss feed */

/* services */

/* staff directory */

/* submission forms */
#submissionforms_module.cms_form .cms_steps_wrapper span a:hover {
	cursor: pointer;
	color: #de1212;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	padding: 10px 20px;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	color: #e00000;
	font-size: 0.9rem;
	font-family: sans-serif;
}

#submissionforms_module.cms_form .required_information em,
.required_information em {
	font-family: sans-serif;
	color: #e00000;
	padding-right: 5px;
}

#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	color: #56565d;
}

/* testimonials */

/* videos */
#video_module.cms_list_carousel .video-container .video-file-youtube iframe,
#video_module.cms_list_carousel .video-container .video-file .cms_media_container,
#video_module.cms_list_carousel .video-container .video-file video {
	background-color: #f0f5f7;
	color: #3a3c41;
	cursor: pointer;
}

/* white papers */

/* newsletters */

/* clinics */
#clinics-module .form-field {
	background: #fff !important;
}

#clinics-module .main-content-wrap.nested-content-wrap {
	margin-right: 0 !important;
	margin-left: 15px;
}

@media (max-width: 479px) {
	#clinics-module .form-field {
		width: 100% !important;
	}
}

/* physicians */
.physician-search-accordion.in-main-content-area {
	background-color: #fff;
	border: 0;
	padding: 0;
}

.physician-search-accordion.in-main-content-area .search-reveal-trigger {
	border: 0;
}

.physician-search-accordion .form-reveal-wrap {
	padding: 0;
	opacity: 0;
}

.physician-search-accordion.in-main-content-area.open .form-reveal-wrap {
	padding: 1rem;
	opacity: 1;
}

.physician-search-accordion.in-main-content-area .title-trigger-box {
	padding: 0 1rem;
	transition: all 300ms ease;
	cursor: pointer;
}

.physician-search-accordion.in-main-content-area.open .title-trigger-box {
	background-color: #de1212;
}

.physician-search-accordion.in-main-content-area .title-trigger-box h2 {
	transition: all 300ms ease;
	color: #3a3c41;
}

.physician-search-accordion.in-main-content-area.open .title-trigger-box h2 {
	transition: all 300ms ease;
	color: #fff;
}

.physician-search-accordion.open .search-reveal-trigger {
	background-image: url('/local/websites/medical.siteviz.com/www/assets/images/icon_arrow2_down_white_50.svg');
}

.physician-search-accordion.in-main-content-area .search-form-section-heading .title-span {
	border: 0;
	color: #3a3c41;
}

.physician-search-accordion.in-main-content-area .search-form-section-heading {
	font-size: 1.25rem;
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 10px;
}

.physician-search-accordion.in-main-content-area .physician-search-field {
	border: 0;
}

.physician-search-accordion.in-main-content-area legend {
	border: 0;
}

.physician-search-accordion.in-main-content-area .search-form-section-heading .title-span {
	border: 0;
}

.physician-search-accordion.in-main-content-area .physician-search-field.by-name fieldset {
	display: flex;
}

.physician-search-accordion.in-main-content-area .physician-search-wrap .physician-search-field .bottom-search-submit {
	margin-top: 15px;
	background-color: #1e7f94 !important;
}

.physician-search-accordion.in-main-content-area .physician-search-wrap .physician-search-field .bottom-search-submit:hover {
	background-color: #de1212 !important;
}

.physician-search-accordion .physician-search-field select {
	background: #fff;
}

.physician-search-accordion .select2-container--default .select2-selection--single {
	background: #fff;
}

#physicians-module.physicians-collection .physician-image a.md-physician-link {
	border-bottom: 0;
}

#physicians-module .md-row.md-patient-comment-block {
	background: #fff !important;
}

@media (max-width: 767px) {
	.select2-container {
		width: 100% !important;
	}

	.physician-search-accordion.in-main-content-area .physician-search-wrap .physician-search-field .bottom-search-submit {
		width: 100%;
	}
}

/* greeding cards */
#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field em {
	color: #e00000 !important;
	font-family: sans-serif;
}

#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-legend-wrapper .ui-form-legend-text {
	color: #3a3c41;
	background-color: #f0f5f7;
}

#greetingcards-module .GreetingCardGeneralInfo {
	padding-right: 10px;
}

#greetingcards-module #greeting-cards-preview {
	padding-left: 10px;
}

@media (min-width: 992px) {
	#greetingcards-module .GreetingCardGeneralInfo div#fffield-roomNumber {
		display: flex;
		flex-direction: column;
	}
}

#greetingcards-module .GreetingCardGeneralInfo .ui-form-label {
	width: 100%;
	left: 0;
}

#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field input,
#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field textarea {
	max-width: none !important;
	padding: 10px 8px;
	height: 100%;
	width: 100% !important;
}

@media (max-width: 991px) {
	#greetingcards-module .greeting-card-form-preview-wrapper .ui-form-label {
		margin-bottom: 0;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field #field-roomNumber {
		width: 60px !important;
	}

	#greetingcards-module .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field #field-roomNumber {
		margin-left: 0;
	}

	#greetingcards-module .GreetingCardGeneralInfo {
		padding-right: 0;
	}

	#greetingcards-module #greeting-cards-preview {
		padding-left: 0;
	}
}

@media (max-width: 479px) {
	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field #field-roomNumber {
		width: 100% !important;
	}
}

/* accounts */
#account_module.form .form_item .label label,
#account_module.form .form_item.required .label label {
	display: block;
	text-align: right;
}

#account_module.form .form_item.required .field {
	background: url('/core/public/shared/assets/images/required.gif') no-repeat 5px 15px;
}

#account_module.form .form_item .label,
#account_module.form .form_item.required .label {
	padding: 0 10px;
	vertical-align: middle;
}

@media (max-width: 991px) {

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: flex;
	}
}

/* Google maps */
/* Marker information */
#popupWindow .location-info-pop-up-directions-read-more a {
	color: #000;
	background: #fff;
}

/* Select2 */
.select2-container-multi .select2-choices .select2-search-field input {
	outline: 2px auto #fff !important;
}


/* Custom landing pages */
.grid-container {
	margin-top: 144px;
	background-color: #fff;
}

.grid-container .contentbox-container {
	padding: 50px 0 30px;
	background-color: #fff;
	margin-right: auto;
	margin-left: auto;
	grid-gap: 15px 30px;
}

.grid-container .contentbox-container.grey-background {
	width: 100%;
	padding: 0px;
	background-color: #f0f5f7;
}

.grid-container .contentbox-container.grey-background .contentbox-container {
	grid-column: 1 / span 12;
	background-color: #f0f5f7;
}

.grid-container .content-box.slideshow {
	overflow: hidden;
	height: 72vh;
	position: relative;
}

.grid-container .content-box.image-on-the-left .content-box-title h3 {
	font-size: 22px;
	margin-top: 0px;
}

.grid-container .content-box.medical-staff {
	text-align: center;
	background-color: #fff;
}

.grid-container .content-box.medical-staff .text-content {
	padding: 20px 15px 20px;
}

.grid-container .content-box.medical-staff .text-content .for-border {
	margin-bottom: 20px;
}

.grid-container .content-box.medical-staff .overlay {
	background: url('/20190104150722/assets/images/doctor-interface-symbol.png') center center no-repeat #67c9e0;
}

.grid-container .content-box.default .content-box-title h2,
.grid-container .content-box.center-text .content-box-title h2 {
	margin: 0 0 10px;
	font-weight: 400;
}

.grid-container .content-box.center-text {
	text-align: center;
}

@media (min-width: 768px) {
	.grid-container .contentbox-container {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.grid-container .contentbox-container {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.grid-container .contentbox-container {
		width: 1170px;
	}
}

@media (max-width: 991px) {
	.grid-container {
		margin-top: 180px;
	}

	.grid-container .contentbox-container {
		padding-left: 15px;
		padding-right: 15px;
	}

	.grid-container .contentbox-container.grey-background {
		padding-left: 0;
		padding-right: 0;
	}

	.grid-container .content-box.slideshow {
		height: 53vh;
	}

	.grid-container .content-box.default {
		text-align: center;
	}

	.grid-container .content-box.default.slideshow {
		text-align: left;
	}

	.grid-container .content-box .slogan-section {
		margin-bottom: 20px;
	}
}

@media (max-width: 767px) {
	.grid-container {
		margin-top: 204px;
	}

	.grid-container .content-box.slideshow {
		height: 100%;
	}

	.grid-container .content-box.image-on-the-left {
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
	}

	.grid-container .content-box.image-on-the-left .icon-wrapper img {
		height: 60px;
	}
}


@media (max-width: 479px) {
	.grid-container {
		margin-top: 261px;
	}

	.grid-container .home-features .single-feature {
		margin-bottom: 30px;
	}

	.grid-container .content-box .slogan-section {
		margin-top: 20px;
	}
}
