@media (min-width: 1600px) {
	.home-slider .content-wrapper,
	.grid-container .content-box.slideshow .content-wrapper {
		top: 25%;
	}
}

@media (min-width: 1300px) and (max-width: 1500px) {
	.home-slider .content-wrapper h1,
	.grid-container .content-box.slideshow .content-wrapper h1 {
		font-size: 44px;
	}

	.home-slider .content-wrapper .slide-content.display-bg h1 {
		font-size: 38px;
	}

	.home-slider .content-wrapper .slide-content.display-bg p {
		font-size: 16px;
	}

	.appoint-var-three {
		background-position: 90% 0;
	}
}

@media (min-width: 1200px) and (max-width: 1299px) {
	.home-slider .content-wrapper,
	.grid-container .content-box.slideshow .content-wrapper {
		left: 50px;
		top: 18%;
	}

	.home-slider .content-wrapper h1,
	.grid-container .content-box.slideshow .content-wrapper h1 {
		font-size: 38px;
	}

	.home-slider .content-wrapper p {
		font-size: 14px;
		line-height: 22px;
	}

	.home-slider .content-wrapper .slide-content.display-bg h1 {
		font-size: 34px;
	}

	.home-slider .content-wrapper .slide-content.display-bg p {
		font-size: 14px;
	}

	.appoint-var-three {
		background-position: 95% 0;
	}
}

@media (min-width: 768px) and (max-width: 850px) {
	.home-slider .content-wrapper,
	.grid-container .content-box.slideshow .content-wrapper {
		display: none;
	}

	.doctors-shortcode .doctor-wrapper {
		margin-bottom: 20px;
	}

	.news-shortcode .common-blog-post {
		margin-bottom: 20px;
	}

	.woocommerce-page table.cart td.actions input {
		width: 100% !important;
		text-align: center;
	}
}

@media (min-width: 1199px) {
	/* homepage flex slider */
	/* home slider */
	.blog-page .left_meta {
		width: 84px;
	}

	.blog-page .right-contents {
		width: 732px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	/* homepage flex slider */
	.home-slider {
		background-color: #fff;
	}

	.home-slider .flexslider {
		min-height: 350px;
	}

	.home-slider .flexslider.loading {
		min-height: 350px;
		background: transparent;
	}

	.home-slider .appointment.appointment-two {
		position: absolute;
		margin-top: 0;
	}

	.home-slider .common.col-md-11 {
		width: 89.666666%;
	}

	.header-top p {
		display: inline-block;
	}

	.appoint-var-three {
		background-position: 100% 0;
	}
}

@media (max-width: 1199px) {
	img,
	iframe {
		max-width: 100%;
	}
	/* homepage flex slider text contents */
	.home-slider .content-wrapper,
	.grid-container .content-box.slideshow .content-wrapper {
		left: 50px;
	}

	.home-slider .content-wrapper h1,
	.grid-container .content-box.slideshow .content-wrapper h1 {
		font-size: 36px;
		margin-bottom: 15px;
	}

	.home-slider .content-wrapper p {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 15px;
	}

	.home-slider .content-wrapper .slide-content.display-bg h1 {
		font-size: 32px;
	}

	.home-slider .content-wrapper .slide-content.display-bg p {
		font-size: 14px;
	}

	.home-slider .make-appoint-one {
		width: 26%;
	}
	/* home testimonials */
	.home-testimonial .flexslider-three .flex-direction-nav .flex-next {
		right: 37%;
	}

	.home-testimonial .flexslider-three .flex-direction-nav .flex-prev {
		left: 37%;
	}

	.home-testimonial.testimonial-var-two .flexslider-three {
		margin-top: -148px;
	}

	.home-slider .variation-two .appointment-form {
		padding: 20px 15px;
	}

	.doctors-posts figure img {
		width: 100%;
	}

	#filters {
		border-bottom: none;
		margin-bottom: 15px;
	}

	#filters li {
		margin-bottom: 10px;
	}

	#filters li a {
		padding-bottom: 10px;
	}

	#filters li.active a,
	#filters li:hover a {
		padding-bottom: 10px;
	}

	.doctors-posts .social-icon ul li {
		margin-bottom: 10px;
	}

	.doctors-single .side-content .content p {
		width: 80%;
	}

	.pricing-table .common.recommended .head span {
		left: 21%;
	}

	.home-team .read-more,
	.home-blog .read-more {
		margin-bottom: 30px;
	}

	.home-team figure img {
		width: 100%;
	}

	.flexslider-var-one {
		margin-bottom: 50px;
	}

	.flexslider-var-one .flex-viewport {
		width: 100%;
		float: none;
	}

	.flexslider-var-one .flex-control-thumbs {
		position: relative;
		width: 100%;
		float: none;
		margin-top: -10px;
	}

	.flexslider-var-one .flex-control-thumbs li {
		margin-left: 5px;
	}
	/* News Page and Single New Page */
	.blog-page .post .left_meta {
		width: 76px;
		margin-right: 20px;
	}

	.blog-page .post .left_meta time {
		padding: 5px 0 10px;
	}

	.blog-page .post .right-contents {
		width: 520px;
	}

	.comments-wrapper {
		margin-left: 96px;
	}
}

@media (max-width: 991px) {
	/* header top */
	.header-top {
		text-align: center;
	}

	.header-top #inspiry_language_list {
		float: none;
	}

	.header-top #inspiry_language_list ul {
		text-align: center;
	}

	.header-top #inspiry_language_list ul li {
		float: none;
		margin: 0 5px;
		display: inline-block;
	}

	.header-top p {
		text-align: center;
	}
	/* header */
	#header nav.main-menu ul.header-nav {
		text-align: center;
	}

	#header nav.main-menu ul.header-nav > li {
		float: none;
		display: inline-block;
	}

	#header nav.main-menu ul.header-nav > li > a {
		padding: 7px 13px;
	}
	/* header nav styles */
	.mean-container .mean-bar a.meanmenu-reveal {
		background-color: #67c9e0;
		padding: 13px 14px;
	}
	/* homepage flex slider */
	.home-slider {
		background-color: #fff;
	}

	.home-slider .flexslider.loading {
		min-height: 300px;
		background: transparent;
	}

	.home-slider .content-wrapper {
		top: 16%;
	}

	.home-slider .content-wrapper h1 {
		font-size: 20px;
		margin-bottom: 10px;
	}

	.home-slider .slide-content h1:after {
		content: '';
	}

	.home-slider .content-wrapper p {
		margin-bottom: 10px;
	}

	.home-slider .content-wrapper .btn {
		font-size: 14px;
		padding: 7px 12px;
	}

	.home-slider .content-wrapper .slide-content.display-bg h1 {
		font-size: 20px;
	}

	.home-slider .content-wrapper .slide-content.display-bg p {
		font-size: 14px;
	}

	.home-slider .common.col-sm-11 {
		width: 86.666667%;
	}

	.appoint-var-three {
		background: #f0f5f7 !important;
	}
	/* home features */
	.home-features .features-intro {
		text-align: center;
		margin: 0 0 60px;
	}
	/* home doctors */
	.margin-gap {
		margin-bottom: 40px;
	}
	/* home services */
	.home-services .tab-content h5 {
		margin: 15px 0;
	}

	.home-services .tab-content p {
		margin-bottom: 20px;
	}
	/* home blog post */
	.home-blog .common-blog-post .text-content {
		text-align: center;
	}

	.home-blog.blog-var-two .col-sm-12 .text-content {
		text-align: center;
	}

	.home-blog .gallery-slider .flex-viewport {
		max-height: none;
	}

	.home-blog.blog-var-two .row > div > .row > div {
		text-align: center;
	}
	/* home testimonials */
	.home-testimonial .flexslider-three .flex-direction-nav .flex-next {
		right: 33%;
	}

	.home-testimonial .flexslider-three .flex-direction-nav .flex-prev {
		left: 33%;
	}

	.home-testimonial blockquote p {
		font-size: 20px;
		line-height: 30px;
	}
	/* News Page and Single New Page */
	.page-top .bread-crumb {
		margin-bottom: 20px;
	}

	.blog-page .post .left_meta {
		width: 85px;
		margin-right: 30px;
	}

	.blog-page .post .left_meta time {
		padding: 5px 0 10px;
	}

	.blog-page .post .right-contents {
		width: auto;
	}

	.comments-wrapper {
		margin-left: 115px;
	}
	/* one columns services page */
	.one-col-service figure {
		margin: 0 -15px;
	}

	.one-col-service .service-contents {
		padding: 10px 15px 25px;
	}

	.overview-var-three .common {
		margin-bottom: 20px;
	}

	#comments-title,
	#comments-section {
		margin-left: 0;
	}

	#comments-section {
		margin-bottom: 50px;
	}

	.services-page figure img {
		width: 100%;
	}

	.services-page .common {
		margin-bottom: 30px;
	}

	.pricing-table .read-more {
		padding: 6px;
		margin-top: 15px;
	}

	.pricing-table .read-more span {
		border: 15px solid transparent;
	}

	.pricing-table .common.recommended .read-more {
		margin-top: 25px;
	}

	.pricing-table .common.recommended .head span {
		left: 11%;
	}

	.pricing-table strong {
		font-size: 24px;
	}

	.tab-widget {
		margin-bottom: 30px;
	}

	.accordion-main.var-two .accordion-title h6 {
		width: 90%;
	}

	.flexslider-var-two .flexslider {
		width: 100%;
	}

	.gallery-slider .flex-direction-nav .flex-prev {
		left: 0px;
	}

	.gallery-slider .flex-direction-nav .flex-next {
		right: 0px;
	}

	.home-blog .common .video iframe {
		width: 100%;
	}

	.isotope-wrapper {
		box-sizing: border-box;
	}
	/* main appointment form */
	.appoint-section form .text-right {
		text-align: left;
	}

	.appoint-section form #appointment-loader {
		margin: 20px;
	}

	.appoint-section form input[type="submit"] {
		display: block;
	}

	.appoint-section #error-container {
		margin: 0 0 20px;
	}

	.appoint-section #message-sent {
		margin: 0 0 20px;
	}
	/* contact sidebar */
	.contact-sidebar p strong {
		margin-right: 5px;
		text-align: left;
		min-width: 0;
	}
	/* appointment captcha */
	#recaptcha_image img {
		height: 60px;
		width: auto;
	}
}

@media (max-width: 767px) {
	/* Responsive Nav styles */
	.mean-container .mean-bar .mean-nav {
		background-color: #53c3dd;
		margin-top: 4px;
	}

	.mean-container .mean-bar .mean-nav ul li a {
		border-color: #73d1e7;
	}

	.mean-container .mean-bar .mean-nav ul li a.mean-expand {
		height: 24px;
	}

	.mean-container .mean-bar a.meanmenu-reveal {
		background-color: #67c9e0;
		padding: 13px 14px;
	}
	/* Home Slider */
	.home-slider .slide-content,
	.grid-container .content-box.slideshow .slide-content {
		display: block;
		padding: 0 75px;
	}

	.home-slider .slide-content h1,
	.grid-container .content-box.slideshow .slide-content h1 {
		font-size: 30px !important;
		margin: 0 0 15px !important;
	}

	.home-slider .slide-content h2,
	.grid-container .content-box.slideshow .slide-content h2 {
		font-size: 30px !important;
		margin: 0 0 15px !important;
	}

	.home-slider .slide-content p,
	.grid-container .content-box.slideshow .slide-content p {
		display: block !important;
		margin-bottom: 15px !important;
	}

	.home-slider .flex-direction-nav {
		display: none;
	}

	.home-slider .make-appoint {
		margin: 0;
	}

	.home-slider .make-appoint-one {
		width: 43%;
	}

	.home-slider .common.col-md-11 {
		width: auto;
		margin-bottom: 20px;
	}
	/* Home Features */
	.home-features .single-feature {
		text-align: center;
		margin-bottom: 40px;
	}

	.home-features .single-feature .icon-wrapper {
		text-align: center;
	}

	.home-features .single-feature h3 {
		margin: 15px 0;
	}

	.features-var-three .single-feature {
		text-align: center;
		margin-bottom: 40px;
	}

	.features-var-three .single-feature h5 {
		margin: 20px 0;
	}
	/* Home Doctors */
	.home-doctors {
		padding: 30px 0 40px;
	}

	.home-doctors .slogan-section {
		text-align: center;
		margin-bottom: 30px;
	}

	.home-doctors .common-doctor img {
		width: 100%;
		height: auto;
	}

	.home-doctors .read-more {
		margin-bottom: 20px;
	}

	.home-doctors.doctors-var-two {
		margin: 30px 0 30px;
	}

	.home-doctors.doctors-var-two .slogan-section {
		text-align: center;
		margin-bottom: 30px;
	}

	.home-doctors.doctors-var-two .text-center {
		text-align: center;
	}
	/* home services */
	.home-services .slogan-section {
		text-align: center;
		margin-bottom: 30px;
	}

	.home-services .tab-main .tab-content {
		margin-left: 0;
	}
	/* Home News */
	.home-blog.blog-var-two .slogan-section {
		text-align: center;
		margin-bottom: 30px;
	}
	/* Home Testimonials */
	.home-testimonial .flexslider-three .flex-direction-nav .flex-next {
		right: 31%;
	}

	.home-testimonial .flexslider-three .flex-direction-nav .flex-prev {
		left: 31%;
	}

	.home-testimonial blockquote p {
		font-size: 20px;
		line-height: 28px;
	}
	/* News Page and Single New Page */
	.page-top h1,
	.page-top h2 {
		float: none;
	}

	.page-top .bread-crumb {
		margin-bottom: 20px;
	}

	.blog-page .post .left_meta {
		display: none;
	}

	.blog-page .post .right-contents {
		width: auto;
	}

	#comments-section,
	.comments-wrapper {
		margin-left: 0px;
	}

	#comments-section .commentlist .children .comment-wrap,
	.comments-wrapper .commentlist .children .comment-wrap {
		width: auto;
		margin-left: 0;
	}

	#comments-section .commentlist .children .children,
	.comments-wrapper .commentlist .children .children {
		margin-left: 0;
	}
	/* common */
	#scroll-top {
		display: none !important;
	}

	.footer-bottom {
		text-align: center;
	}

	.footer-bottom .footer-social-nav {
		float: none;
		margin: 15px 0;
	}
	/* contact page */
	#contact_form {
		padding-bottom: 30px;
		border-bottom: 1px solid #dcdee4;
		margin-bottom: 30px;
	}

	#contact_form input[type="submit"] {
		float: none;
		margin: 0 20px 0 0;
	}

	#contact_form #contact-loader {
		float: none;
		display: inline-block;
		margin: 0;
	}

	#contact_form #error-container {
		margin: 20px 0 10px;
	}

	.contact-sidebar .social-icon h5 {
		display: none;
	}
	/* Gallery Single Post */
	.gallery-single .next-prev-posts {
		display: none;
	}

	.gallery-single .gallery-single-post {
		margin-bottom: 60px;
	}

	.gallery-single #carousel {
		display: none;
	}

	.doctors-shortcode .doctor-wrapper {
		margin-bottom: 20px;
	}

	.news-shortcode .common-blog-post {
		margin-bottom: 20px;
	}

	.woocommerce-page table.cart td.actions input {
		width: 100% !important;
		text-align: center;
	}
}

@media only screen and (max-width: 530px) {
	/* Home Testimonials */
	.home-testimonial .flexslider-three .flex-direction-nav .flex-next {
		right: 25%;
	}

	.home-testimonial .flexslider-three .flex-direction-nav .flex-prev {
		left: 25%;
	}
	/* Responsive Nav styles */
	.mean-push {
		margin-bottom: 15px;
	}

	.mean-container .mean-bar {
		background: #53c3dd;
		margin-top: 0;
		min-height: 40px;
		padding: 0;
	}

	.mean-container .mean-bar .mean-nav {
		margin-top: 48px;
	}

	.mean-container .mean-bar a.meanmenu-reveal {
		top: 0;
		padding: 13px 14px;
	}
	/* Main appointment form */
	.appoint-page .appoint-section {
		padding: 20px;
	}

	.appoint-page .appoint-section .top-icon {
		display: none;
	}

	.appoint-page form {
		margin-top: 0;
		padding: 40px 20px;
	}

	.doctors-shortcode .doctor-wrapper {
		margin-bottom: 20px;
	}

	.news-shortcode .common-blog-post {
		margin-bottom: 20px;
	}

	.woocommerce-page table.cart td.actions input {
		width: 100% !important;
		text-align: center;
	}
}

@media only screen and (max-width: 400px) {
	/* Gallery Single Post */
	.gallery-single .flex-direction-nav {
		display: none;
	}

	.blog-page .format-quote blockquote.quote {
		padding: 30px;
		background-image: none;
	}

	.blog-page .format-quote blockquote.quote p {
		border-left: none;
		padding: 0;
	}

	.blog-page .format-link .link {
		padding: 25px;
		background-image: none;
	}

	.blog-page .format-link .link-container {
		padding: 0;
		border-left: none;
	}

	.doctors-shortcode .doctor-wrapper {
		margin-bottom: 20px;
	}

	.news-shortcode .common-blog-post {
		margin-bottom: 20px;
	}

	.woocommerce-page table.cart td.actions input {
		width: 100% !important;
		text-align: center;
	}
}

@media only screen and (max-width: 340px) {
	.home-testimonial .flexslider-three .flex-direction-nav .flex-next {
		right: 10%;
	}

	.home-testimonial .flexslider-three .flex-direction-nav .flex-prev {
		left: 10%;
	}

	.doctors-single h2 {
		margin-bottom: 10px;
	}

	.blog-page .post .flex-direction-nav a {
		width: 40px;
	}

	iframe {
		height: auto;
	}

	.pricing-table .common.recommended .head span {
		width: 50%;
	}

	.accordion-main.var-two .accordion-title h6 {
		width: 78%;
		padding-right: 0;
	}

	.default-page h1 {
		font-size: 32px;
	}

	.doctors-shortcode .doctor-wrapper {
		margin-bottom: 20px;
	}

	.news-shortcode .common-blog-post {
		margin-bottom: 20px;
	}

	.woocommerce-page table.cart td.actions input {
		width: 100% !important;
		text-align: center;
	}
}

/*------------------------------------------------------*/
/* Common Styles for overall theme
/*------------------------------------------------------*/
body {
	background: #f0f5f7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #3a3c41;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
	font-weight: 700;
	color: #3a3c41;
}

a,
a:hover,
a:focus {
	text-decoration: none;
}

button::-moz-focus-inner {
	border: 0;
}

input::-moz-focus-inner {
	border: 0;
}

img {
	max-width: 100%;
	height: auto;
}

label {
	color: #3a3c41;
	font-weight: 500;
	font-size: 16px;
}
/* slogan section */
.slogan-section {
	margin-bottom: 50px;
	text-align: center;
}

.slogan-section h2 {
	margin: 0 0 10px;
	font-weight: 400;
}

.text-left {
	text-align: left;
}

.line-section {
	margin-bottom: 20px;
}

.line-section h3,
.line-section h4,
.line-section h5 {
	margin: 0 0 20px;
}

.for-border {
	height: 11px;
	width: 100%;
	background: transparent url('/20190104150722/assets/images/feature-border.png') center center no-repeat;
	border: none;
}

h2 {
	font-size: 30px;
}

h2.blue {
	font-weight: 800;
	color: #66c9e0;
}

h2.blue a {
	font-weight: 800;
	color: #66c9e0;
}

h2.blue a:hover {
	color: #65a1b0;
}

h3 {
	font-size: 24px;
	font-weight: 700;
}

h3 a {
	font-size: 24px;
	color: #3a3c41;
	font-weight: 700;
}

h3.blue {
	font-weight: 700;
	color: #66c9e0;
}

h3.blue a {
	font-weight: 800;
	color: #66c9e0;
}

h3.blue a:hover {
	color: #65a1b0;
}

h4 {
	font-size: 21px;
	font-weight: 700;
}

h5 {
	font-size: 18px;
	font-weight: 700;
}

h5 a {
	font-size: 18px;
	font-weight: 700;
	color: #3a3c41;
}

h6 {
	font-size: 14px;
}

@media (max-width: 991px) {
	h1,
	.h1 {
		font-size: 32px;
	}

	h2,
	.h2 {
		font-size: 27px;
	}

	h3,
	.h3 {
		font-size: 22px;
	}
}

@media (max-width: 767px) {
	h1,
	.h1 {
		font-size: 28px;
	}

	h2,
	.h2 {
		font-size: 25px;
	}
}

p {
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	line-height: 24px;
}

.btn {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
	padding: 10px 15px;
	border-radius: 0;
}

a:active {
	background-color: transparent;
}

input,
textarea {
	font-size: 14px;
	font-weight: 600;
	color: #9ba0aa;
	resize: none;
}

.btn,
.read-more {
	font-size: 14px;
	font-weight: 700;
	background-color: #1e7f94;
	padding: 10px 20px;
	color: #fff;
	text-decoration: none;
	display: inline-block;
	letter-spacing: 1px;
}

.btn:active,
.read-more:active,
.btn:focus,
.read-more:focus,
.btn:hover,
.read-more:hover {
	background-color: #de1212;
	color: #fff;
}

.read-more.black {
	background-color: #3a3c41;
}

.read-more.black:active,
.read-more.black:focus,
.read-more.black:hover {
	background-color: #de1212;
}

.read-more.red {
	background-color: #de1212;
}

.read-more.red:active,
.read-more.red:focus,
.read-more.red:hover {
	background-color: #e15251;
}

.read-more.orange {
	background-color: #ffa13c;
}

.read-more.orange:active,
.read-more.orange:focus,
.read-more.orange:hover {
	background-color: #e19132;
}

.read-more.yellow {
	background-color: #ffd543;
}

.read-more.yellow:active,
.read-more.yellow:focus,
.read-more.yellow:hover {
	background-color: #e1b63f;
}

.read-more.green {
	background-color: #6dc068;
}

.read-more.green:active,
.read-more.green:focus,
.read-more.green:hover {
	background-color: #65b760;
}

.common-style {
	padding: 81px 0;
}

.nothing-found {
	padding: 20px;
	background-color: #fff;
}

.arrow-style {
	padding-left: 0;
	text-align: left;
}

.arrow-style li {
	background: url("../images/list-arrow-style.png") left 3px no-repeat;
	padding-left: 25px;
	line-height: 21px;
	margin-bottom: 30px;
	list-style: none;
	color: #999ca5;
}

.plus-icon:after {
	content: url("../images/plus-red2.png");
	margin-left: 10px;
}

::-webkit-input-placeholder {
	color: #9ba0aa;
}

::-moz-placeholder {
	color: #9ba0aa;
}

:-ms-input-placeholder {
	color: #9ba0aa;
}

input:-moz-placeholder {
	color: #9ba0aa;
}

#message-sent {
	clear: both;
}

.gray-scale img:hover {
	-ms-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
}

.gray-scale img {
	transition: all 0.2s linear;
}

.isotope-wrapper {
	box-sizing: content-box;
	position: relative;
}
/*------------------------------------------------------*/
/* WordPress Core
/*------------------------------------------------------*/
a img.alignnone,
.alignnone {
	float: none;
}

.aligncenter,
a img.aligncenter,
div.aligncenter {
	display: block;
	margin: 20px auto;
}

a img.alignright,
.alignright {
	float: right;
	margin: 0 0 15px 20px;
}

a img.alignleft,
.alignleft {
	float: left;
	margin: 0 20px 15px 0;
}

p.wp-caption {
	margin: 0;
}

.wp-caption.alignnone {
	float: none;
}

.wp-caption.alignleft {
	float: left;
}

.wp-caption.alignright {
	float: right;
}

.wp-caption p.wp-caption-text {
	margin: 0;
	margin-top: 5px;
	font-weight: 400;
	text-align: center;
	font-size: 11px;
	letter-spacing: 1px;
	color: #7b7d85;
}

blockquote p {
	margin-bottom: 0;
}

.wp-caption-text,
.gallery-caption {
	margin: 0;
	margin-top: 5px;
	font-weight: 400;
	text-align: center;
	font-size: 11px;
	letter-spacing: 1px;
	color: #888;
}
/*------------------------------------------------------*/
/* Layout
/*------------------------------------------------------*/
@media (min-width: 1200px) {
	.inspiry-boxed-layout #page {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		background-color: #fff;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	}

	.inspiry-boxed-layout .container {
		width: auto;
		padding-left: 30px;
		padding-right: 30px;
	}

	.inspiry-boxed-layout .flexslider .content-wrapper {
		transition: padding 0.3s ease-in-out;
	}

	.inspiry-boxed-layout .flexslider:hover .content-wrapper {
		padding-left: 45px;
		padding-right: 45px;
	}

	.inspiry-boxed-layout .flex-direction-nav a {
		z-index: 1000;
	}
}
/*------------------------------------------------------*/
/* Similar Page styles
/*------------------------------------------------------*/
.banner {
	width: 100%;
	min-height: 300px;
}

.page-top {
	padding: 27px 0;
	background: #fff;
}

.page-top h1,
.page-top h2 {
	margin: 0 15px 0 0;
	font-size: 30px;
	font-weight: 400;
	float: left;
	line-height: 46px;
}

.bread-crumb {
	padding: 0;
	font-size: 14px;
	font-weight: 400;
	margin-top: 17px;
}

.bread-crumb .breadcrumb {
	padding: 0;
	background: none;
	border-radius: 0;
	margin: 0;
}

.bread-crumb .breadcrumb li {
	display: block;
	float: left;
}

.bread-crumb .breadcrumb > li + li:before {
	content: "";
	padding: 0;
}

.bread-crumb .breadcrumb li.active {
	color: #f15b5a;
}

.bread-crumb .divider {
	display: inline-block;
	background: url("../images/breadcrumbs-divider.png") center center no-repeat;
	width: 24px;
	height: 10px;
}

.bread-crumb a {
	font-weight: 400;
	color: #3a3c41;
	display: inline-block;
}

.bread-crumb a:hover {
	text-decoration: underline;
}

#search {
	margin-bottom: 0;
}

#search form {
	position: relative;
}

#search form input[type="text"] {
	background-color: #f0f5f7;
	width: 100%;
	padding: 13px 10px 13px 40px;
	border: none;
	font-weight: 400;
	color: #3a3c41;
	margin-bottom: 0;
}

#search form input[type="submit"] {
	background: url("../images/search-submit.png") center no-repeat;
	width: 12px;
	height: 12px;
	position: absolute;
	left: 15px;
	top: 16px;
	z-index: 999;
	border: none;
	padding: 0;
}

#filters {
	padding: 0;
	border-bottom: 1px solid #dcdee4;
	margin-bottom: 30px;
}

#filters li {
	list-style: none;
	display: inline-block;
	margin: 0 18px -1px 0;
	padding-bottom: 2px;
}

#filters li a {
	font-size: 14px;
	font-weight: 400;
	padding-bottom: 20px;
	display: inline-block;
	text-decoration: none;
}

#filters li:hover {
	padding-bottom: 0;
	border-bottom: 2px solid #f15b5a;
}

#filters li:hover a {
	padding-bottom: 20px;
}

#filters li.active {
	padding-bottom: 0;
	border-bottom: 2px solid #f15b5a;
}

#filters li.active a {
	padding-bottom: 20px;
}

.pagination {
	display: block;
	text-align: center;
	margin-left: -8px;
}

.pagination a,
.pagination span {
	background-color: #fff;
	font-size: 16px;
	color: #3a3c41;
	display: inline-block;
	margin: 0 0 8px 8px;
	font-weight: 700;
	padding: 10px 20px;
	text-decoration: none;
}

.pagination a:hover,
.pagination span:hover {
	background-color: #66c9e0;
	color: #fff;
}

.pagination span.current {
	background-color: #66c9e0;
	color: #fff;
}

.social-icon ul {
	padding: 0;
}

.social-icon ul li {
	list-style: none;
	background-color: #dcdee4;
	display: inline-block;
}

.social-icon ul li a {
	width: 36px;
	height: 36px;
	display: inline-block;
}

.social-icon ul li .fa,
.social-icon ul li .fab,
.social-icon ul li .fal,
.social-icon ul li .far,
.social-icon ul li .fas {
	font-size: 18px;
	color: #fff;
	display: block;
	text-align: center;
	line-height: 36px;
}

.social-icon ul li:hover {
	background-color: #66c9e0;
	transition: all 0.3s ease;
}

code {
	white-space: normal;
}

#scroll-top {
	display: inline;
}

.loader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: #67c9e0;
	text-align: center;
	display: table;
	z-index: 99999999;
}

.loader .table-cell {
	display: table-cell;
	vertical-align: middle;
	min-height: 100%;
}

.loader h1 {
	margin-top: 2rem;
	font-size: 4rem;
	line-height: 4rem;
	color: #fff;
	font-weight: 800;
}

form input[type="text"],
form input[type="email"],
form input[type="search"],
form input[type="url"],
form input[type="tel"],
form input[type="number"],
form input[type="date"],
form input[type="range"],
form input[type="password"],
form input[type="text"],
form textarea {
	width: 100%;
	height: auto;
	border: 1px solid #dcdee4;
	background-color: #fff;
	margin-bottom: 20px;
	padding: 11px 10px;
	color: #3a3c41;
	font-weight: 500;
}

form input[type="file"] {
	margin-bottom: 30px;
	color: #3a3c41;
}

form input[type="submit"] {
	padding: 12px 25px;
	background-color: #3a3c41;
	font-size: 14px;
	color: #fff;
	font-weight: 700;
	border: none;
	cursor: pointer;
	letter-spacing: 1px;
}

form input[type="submit"]:hover {
	background-color: #f15b5a;
}

form input.error,
form textarea.error {
	border: 1px solid #ff0000;
}

form fieldset label {
	font-size: 14px;
	color: #6c6f7a;
	font-weight: 500;
	border-bottom: 1px solid #dcdee4;
	padding: 5px;
	float: left;
}

form fieldset .common {
	display: inline-block;
	float: left;
	margin-left: 7px;
}

form p {
	margin: 0;
}

textarea,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="number"],
input[type="date"],
input[type="range"],
input[type="password"],
input[type="text"] {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}

#error-container {
	margin: 0;
}

#error-container label {
	font-size: 14px;
	display: block;
}
/* IE8 Specific Styles */
.ie8 img {
	width: inherit;
	max-width: 100% !important;
	height: auto !important;
}

.ie8 .features-var-three .feature-icon img,
.ie8 .features-var-two .single-feature img,
.ie8 .home-testimonial .flexslider-three .slides img,
.ie8 .appoint-page .appoint-section .top-icon img {
	width: auto !important;
}

.ie8 .overlay {
	visibility: hidden;
}
/*------------------------------------------------------*/
/*	Header styles
/*------------------------------------------------------*/
.header-top {
	background-color: #60646d;
	padding: 11px 0;
}

.header-top p {
	color: #bbbfc9;
}

.header-top p span {
	color: #fefefe;
	white-space: pre;
}

.header-top #inspiry_language_list {
	margin-left: 5px;
	float: right;
}

.header-top #inspiry_language_list ul {
	list-style: none;
	padding: 0;
}

.header-top #inspiry_language_list ul li {
	float: left;
	margin: 0 0 0 10px;
}

.header-top #inspiry_language_list ul li img {
	margin: 0 0 0 0;
	vertical-align: bottom;
}

.header-top #inspiry_language_list ul li span {
	font-size: 12px;
}

.header-top #inspiry_language_list ul li a {
	font-size: 12px;
	color: #bbbfc9;
}

.header-top #inspiry_language_list ul li a:hover {
	color: #fefefe;
}

.nav-toggle {
	display: none;
}

#header {
	position: fixed;
	top: 0px;
	width: 100%;
	background: #fff;
}

#header .logo {
	text-align: center;
	margin-bottom: 30px;
}

.header-bottom {
	padding: 30px 0;
	border-bottom: 1px solid #e0e9ec;
}

@media (max-width: 479px) {
	.header-bottom {
		padding: 20px 0;
	}
}

@media (min-width: 480px) {
	#header .logo {
		float: left;
		margin-bottom: 0;
	}
}

#header .logo h1 {
	margin: 0;
}

#header .logo h1 a {
	font-size: 30px;
	font-weight: 800;
}

@media (min-width: 768px) {
	nav.main-menu {
		float: right;
	}
}

nav.main-menu ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

nav.main-menu ul > li {
	margin: 0;
	float: left;
	position: relative;
}

nav.main-menu ul > li > a {
	width: 100%;
	display: block;
	padding: 8px 15px;
	text-decoration: none;
	color: #60646d;
	font-size: 16px;
	font-weight: 400;
	text-align: left;
}

nav.main-menu ul > li ul {
	z-index: 9999;
	padding: 0;
	margin: 0;
	position: absolute;
	top: 36px;
	left: 0;
	width: 190px;
	display: none;
	background-color: #67c9e0;
}

nav.main-menu ul > li ul li {
	list-style: none;
	float: none;
	position: relative;
	border-bottom: 1px solid #73d1e7;
}

nav.main-menu ul > li ul li a {
	font-size: 14px;
	color: #fff;
	padding: 10px 20px;
}

nav.main-menu ul > li ul li:hover a {
	background-color: #53c3dd;
}

nav.main-menu ul > li ul li ul {
	position: absolute;
	left: 190px;
	top: 0;
}

nav.main-menu ul > li.two-cols-menu ul {
	width: 350px;
}

nav.main-menu ul > li.two-cols-menu ul li {
	width: 50%;
	float: left;
}

nav.main-menu ul > li.two-cols-menu ul li ul {
	left: 175px;
}

nav.main-menu ul > li:hover > a,
nav.main-menu ul > .current-menu-item > a {
	background-color: #67c9e0;
	color: #fff;
}

#header.stick {
	position: fixed;
	z-index: 9999;
	width: 100%;
	top: 0;
}

.mean-container .mean-nav {
	margin-top: 0;
}

.mean-container .mean-bar {
	min-height: inherit;
}

@media (min-width: 531px) {
	.mean-container .mean-bar {
		background: transparent;
	}

	.mean-container .mean-bar a.meanmenu-reveal {
		top: -36px;
	}
}
/*------------------------------------------------------*/
/* Main Slider styles
/*------------------------------------------------------*/
.home-slider {
	position: relative;
}

.home-slider .flexslider {
	background: none;
	border: none;
	margin: 0;
	border-radius: 0;
	overflow: hidden;
}

.home-slider .flexslider.loading {
	min-height: 550px;
	background: transparent url('../images/slider-loader.gif') no-repeat center center;
}

.home-slider .flex-direction-nav a {
	width: 53px;
	height: 53px;
	margin: -27px 0 0;
	border-radius: 50%;
	font-size: 0;
}

.home-slider .flex-direction-nav a:before {
	display: none;
}

.home-slider .flex-direction-nav .flex-next {
	background: url("../images/slider-right-arrow.png") 21px center no-repeat #f15b5a;
}

.home-slider .flex-direction-nav .flex-prev {
	background: url("../images/slider-left-arrow.png") 17px center no-repeat #f15b5a;
}

.home-slider .content-wrapper,
.grid-container .content-box.slideshow .content-wrapper {
	position: absolute;
	top: 20%;
	left: 0;
	right: 0;
	z-index: 999;
}

.home-slider .slide-content,
.grid-container .content-box.slideshow .content-wrapper .slide-content {
	width: 55%;
}

@media (min-width: 1200px) {
	.home-slider .slide-content,
	.grid-container .content-box.slideshow .content-wrapper .slide-content {
		width: 44%;
	}
}

.home-slider .slide-content h1,
.home-slider .slide-content h2,
.grid-container .content-box.slideshow .content-wrapper .slide-content h1,
.grid-container .content-box.slideshow .content-wrapper .slide-content h2 {
	font-size: 36px;
	margin: 0 0 20px;
	font-weight: 400;
}

.home-slider .slide-content h1 span,
.home-slider .slide-content h2 span,
.grid-container .content-box.slideshow .content-wrapper .slide-content h1 span,
.grid-container .content-box.slideshow .content-wrapper .slide-content h2 span {
	font-weight: 800;
}

@media (min-width: 1200px) {
	.home-slider .slide-content h1,
	.home-slider .slide-content h2,
	.grid-container .content-box.slideshow .content-wrapper .slide-content h1,
	.grid-container .content-box.slideshow .content-wrapper .slide-content h2 {
		font-size: 48px;
	}
}

.home-slider .slide-content h1:after,
.home-slider .slide-content h2:after {
	content: url("../images/plus-red.png");
	margin-left: 10px;
}

.home-slider .slide-content p {
	display: none;
	font-size: 14px;
	color: #3a3c41;
	margin-bottom: 20px;
	line-height: 26px;
}

.grid-container .content-box.slideshow .content-wrapper .slide-content p {
	font-size: 14px;
	color: #3a3c41;
	margin-bottom: 20px;
	line-height: 26px;
}

@media (min-width: 992px) {
	.home-slider .slide-content p,
	.grid-container .content-box.slideshow .content-wrapper .slide-content p {
		display: block;
	}
}

@media (min-width: 1200px) {
	.home-slider .slide-content p,
	.grid-container .content-box.slideshow .content-wrapper .slide-content p {
		font-size: 18px;
	}
}

.home-slider .slide-content .btn,
.grid-container .content-box.slideshow .slide-content .btn {
	padding: 10px 30px;
	background-color: #de1212;
}

.home-slider .slide-content .btn:hover,
.grid-container .content-box.slideshow .slide-content .btn:hover {
	background-color: #3a3c41;
	color: #fff;
}

.home-slider .slide-content.display-bg {
	background-color: rgba(225, 255, 255, 0.6);
	padding: 20px;
}

.home-slider .slide-content.display-bg h1,
.grid-container .content-box.slideshow .content-wrapper .slide-content h1 {
	font-size: 42px;
}

@media (min-width: 768px) {
	.home-slider .appointment {
		margin-top: -37px;
	}
}

@media (min-width: 991px) {
	.home-slider .appointment {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
}

.home-slider .make-appoint {
	font-size: 15px;
	background-color: #3a3c41;
	display: block;
	text-align: left;
	position: relative;
	z-index: 99;
	padding: 7px 15px;
}

.home-slider .make-appoint i {
	width: 42px;
	padding: 9px 0;
	font-size: 18px;
	display: inline-block;
	position: absolute;
	right: 0;
	top: 0;
	color: #fff;
	text-align: center;
	transition-duration: 0.2s;
}

.home-slider .make-appoint:hover {
	background-color: #000;
	color: #fff;
}

.home-slider .make-appoint.open i {
	transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	/* IE 9 */
	-webkit-transform: rotate(180deg);
	/* Safari and Chrome */
}

.home-slider .appointment-two input[type="submit"] {
	margin-top: 15px;
}

.home-slider .variation-two .appointment-form {
	padding: 30px 20px;
}

.home-slider .variation-two #appointment-loader {
	margin-top: 15px;
}

.home-slider .make-appoint-one {
	width: 22%;
}

.home-slider .make-appoint-one .make-appoint .fa {
	background: none;
}

.home-slider .appointment-form {
	background-color: #67c9e0;
	padding: 16px 10px 15px;
	position: relative;
	z-index: 999999;
}

.home-slider .appointment-form label.error {
	display: none !important;
}

.home-slider .common {
	padding-left: 9px;
	padding-right: 9px;
}

.home-slider input[type="text"],
.home-slider input[type="tel"],
.home-slider input[type="email"],
.home-slider input[type="number"],
.home-slider textarea {
	background: none;
	color: #fff;
	border: 0;
	border-bottom: 2px solid #adebfa;
	width: 100%;
	display: inline-block;
	resize: none;
	padding: 0 5px 5px 5px;
	margin-bottom: 0;
}

.home-slider textarea {
	height: auto;
	margin: 0;
	line-height: 20px;
	transition: height 0.2s;
}

.home-slider input[type="text"]:focus,
.home-slider input[type="tel"]:focus,
.home-slider input[type="email"]:focus,
.home-slider textarea:focus {
	border-bottom: 2px solid #fff;
}

.home-slider input[type="text"],
.home-slider input[type="tel"],
.home-slider input[type="email"] {
	margin-bottom: 23px;
}

.home-slider input[type="submit"] {
	font-size: 14px;
	font-weight: 700;
	border: 0;
	padding: 6px 19px;
	color: #3a3c41;
	background-color: #fff;
	border: none;
	border-image: none;
	letter-spacing: 0;
}

.home-slider input[type="submit"]:hover {
	background-color: #3a3c41;
	color: #fff;
}

.home-slider input[type="submit"]:focus {
	background-color: #fafafa;
	color: #3a3c41;
}

.home-slider input.error,
.home-slider textarea.error {
	border: 0;
	border-bottom: 2px solid #ff0000;
}

.home-slider input.error:focus,
.home-slider textarea.error:focus {
	border-bottom: 2px solid #fff;
}

.home-slider ::-webkit-input-placeholder {
	color: #fff;
}

.home-slider :-moz-placeholder {
	color: #fff;
}

.home-slider ::-moz-placeholder {
	color: #fff;
}

.home-slider :-ms-input-placeholder {
	color: #fff;
}

.home-slider #message-sent {
	clear: both;
	padding: 10px 0;
	font-weight: 600;
	color: #fff;
	display: none;
}

#appointment_form_two .gdpr-checkbox-wrapper {
	margin-top: 1.4rem;
	margin-bottom: 0;
	color: #fff;
}

@media (min-width: 992px) {
	#appointment_form_two .gdpr-checkbox-wrapper {
		position: relative;
		overflow: hidden;
	}

	#appointment_form_two .gdpr-checkbox-wrapper:hover {
		overflow: visible;
	}

	#appointment_form_two .gdpr-checkbox-wrapper:hover .gdpr-text {
		opacity: 1;
		bottom: 100%;
	}

	#appointment_form_two .gdpr-checkbox-wrapper .gdpr-checkbox-label {
		display: inline-block;
		font-weight: normal;
		margin-bottom: 0;
	}

	#appointment_form_two .gdpr-checkbox-wrapper input[type="checkbox"] {
		margin-top: 4px;
		float: left;
	}

	#appointment_form_two .gdpr-checkbox-wrapper .gdpr-text {
		position: absolute;
		bottom: 200%;
		left: 0;
		padding: 5px 0;
		background-color: #66c9e1;
		z-index: 1;
		opacity: 0;
		transition: all 0.2s linear;
	}
}

#appointment_form_two .inspiry-recaptcha-wrapper {
	margin-top: 1.4rem;
}

#appointment_form_two .inspiry-google-recaptcha > div {
	max-width: 100% !important;
}

#appointment_form_two .recaptcha-col {
	padding-left: 9px;
}

#appointment_form_one #app-message {
	margin-top: 5px;
	margin-bottom: 0;
}

#appointment_form_one #appointment-loader {
	margin-top: 10px;
}

.appoint-var-three {
	padding: 40px 0;
	background-position: 85% 0;
	background-repeat: no-repeat;
	background-color: #f0f5f7;
}

.appoint-var-three .slogan-section {
	text-align: left;
	margin-bottom: 30px;
}

.appoint-var-three .slogan-section h3 {
	margin: 0 0 10px;
}

.appoint-var-three .appointment-form input[type="text"],
.appoint-var-three .appointment-form input[type="tel"],
.appoint-var-three .appointment-form input[type="email"],
.appoint-var-three .appointment-form textarea {
	background: none;
	border: 0;
	border-bottom: 2px solid #d3d8da;
	width: 100%;
	display: inline-block;
	resize: none;
	padding: 0 5px 5px 5px;
}

.appoint-var-three .appointment-form input[type="text"]:focus,
.appoint-var-three .appointment-form input[type="tel"]:focus,
.appoint-var-three .appointment-form input[type="email"]:focus,
.appoint-var-three .appointment-form textarea:focus {
	border-bottom: 2px solid #fff;
}

.appoint-var-three .appointment-form ::-webkit-input-placeholder {
	color: #a7adaf;
}

.appoint-var-three .appointment-form :-moz-placeholder {
	color: #a7adaf;
}

.appoint-var-three .appointment-form ::-moz-placeholder {
	color: #a7adaf;
}

.appoint-var-three .appointment-form :-ms-input-placeholder {
	color: #a7adaf;
}

.appoint-var-three .appointment-form input[type="text"],
.appoint-var-three .appointment-form input[type="tel"],
.appoint-var-three .appointment-form input[type="email"] {
	margin-bottom: 23px;
}

.appoint-var-three .appointment-form input[type="submit"] {
	font-size: 14px;
	border: 0;
	padding: 10px 20px;
	color: #fff;
	background-color: #f15b5a;
	margin: 5px 0 20px;
}

.appoint-var-three .appointment-form input[type="submit"]:hover {
	background-color: #898e99;
}

.appoint-var-three .appointment-form input.error,
.appoint-var-three .appointment-form textarea.error {
	border: 0;
	border-bottom: 2px solid #ff0000;
}

.appoint-var-three .appointment-form #appointment-loader {
	margin: -10px 0 0 20px;
}

.appoint-var-three .appointment-form #message-sent {
	clear: both;
	font-weight: 600;
	font-size: 16px;
}

.appoint-var-three .inspiry-recaptcha-wrapper {
	margin-bottom: 2rem;
}

.appoint-var-three #response-container:not(:empty),
.appoint-var-three #error-container:not(:empty),
.appoint-var-three #message-sent:not(:empty) {
	margin-top: 0;
}

#appointment-loader {
	display: none;
}
/*------------------------------------------------------*/
/* Home Contents
/*------------------------------------------------------*/
.default-contents {
	margin: 60px 0;
}
/*------------------------------------------------------*/
/* Home Features Styles
/*------------------------------------------------------*/
.home-features {
	padding: 90px 0 30px;
	background-color: #fff;
}

.home-features .features-intro h2 {
	margin: 0 0 30px;
	font-weight: 400;
}

.home-features .features-intro p {
	margin-bottom: 30px;
}

.home-features .features-intro a,
.home-features .features-intro .read-more {
	background-color: #f15b5a;
	color: #fff;
	padding: 15px 30px;
	text-transform: uppercase;
	font-size: 16px;
}

.home-features .features-intro a:hover,
.home-features .features-intro .read-more:hover {
	background-color: #3a3c41;
	color: #fff;
}

.home-features .single-feature {
	margin-bottom: 60px;
}

.home-features .single-feature h3 {
	margin: 0 0 15px;
	font-size: 22px;
}

.home-features .icon-wrapper {
	text-align: right;
}

.home-features .icon-wrapper .fa {
	font-size: 60px;
	color: #67c9e0;
}
/*------------------------------------------------------*/
/* Home Features Variation Two
/*------------------------------------------------------*/
.features-var-two {
	padding: 90px 0 30px;
	background-color: #fff;
}

.features-var-two .slogan-section {
	margin-bottom: 50px;
}

.features-var-two .single-feature {
	margin-bottom: 60px;
}

.features-var-two .single-feature img {
	margin: 0 auto 30px;
}

.features-var-two .single-feature h3 {
	margin: 0 0 20px;
	font-size: 18px;
}

.features-var-two .single-feature h3 a {
	font-size: 18px;
}

.features-var-two .single-feature .feature-border {
	height: 11px;
	background: transparent url('../images/feature-border.png') no-repeat center center;
	margin: 0 0 20px;
}
/*------------------------------------------------------*/
/* Home Features Variation Three
/*------------------------------------------------------*/
.features-var-three {
	padding: 90px 0 30px;
	background-color: #fff;
}

.features-var-three .slogan-section {
	margin-bottom: 60px;
}

.features-var-three .single-feature {
	margin-bottom: 55px;
}

.features-var-three .single-feature h5 {
	margin: 0 0 15px;
	font-weight: 500;
	font-size: 18px;
}

.features-var-three .single-feature h5 a {
	font-size: 18px;
}

.features-var-three .single-feature p {
	color: #b0b1b6;
}

.features-var-three .feature-icon img {
	transition-duration: 0.5s;
	transition-property: transform;
}

.features-var-three .single-feature:hover img {
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-o-transform: rotateY(180deg);
}
/*------------------------------------------------------*/
/*	Home Team Styles
/*------------------------------------------------------*/
.home-doctors {
	text-align: center;
	padding: 70px 0 40px;
}

.home-doctors .doctor-wrapper {
	margin-bottom: 40px;
}

.home-doctors .common-doctor {
	background-color: #fff;
}

.home-doctors .common-doctor figure {
	overflow: hidden;
}

.home-doctors .common-doctor h5 {
	margin: 0 0 20px;
}

.home-doctors .common-doctor .for-border {
	margin-bottom: 20px;
}

.home-doctors .common-doctor .text-content {
	padding: 20px 15px 20px;
}

.home-doctors .overlay {
	background: url("/20190104150722/assets/images/doctor-interface-symbol.png") center center no-repeat #67c9e0;
}

.home-doctors.doctors-var-two {
	text-align: left;
	background-color: #fff;
}

.home-doctors.doctors-var-two .slogan-section {
	text-align: left;
}

.home-doctors.doctors-var-two .common-doctor {
	background-color: transparent;
}

.home-doctors.doctors-var-two .common-doctor .for-border {
	display: none;
}

.home-doctors.doctors-var-two .common-doctor .text-content {
	padding: 20px 0 20px;
}

.home-doctors.doctors-var-two .text-center {
	text-align: left;
}
/*------------------------------------------------------*/
/* Home Blog Styles
/*------------------------------------------------------*/
.home-blog {
	padding: 90px 0;
	background-color: #fff;
}

.home-blog .common-blog-post {
	background-color: #f0f5f7;
	line-height: 20px;
}

.home-blog .common-blog-post figure {
	overflow: hidden;
}

.home-blog .common-blog-post .text-content {
	padding: 25px;
	text-align: left;
}

.home-blog .common-blog-post .text-content h5 {
	margin: 0 0 10px;
}

.home-blog .common-blog-post .text-content p {
	margin: 0 0 15px;
}

.home-blog .common-blog-post .entry-meta {
	margin: 0 0 15px;
	font-style: italic;
}

.home-blog .common-blog-post .entry-meta time {
	color: #a1a9b0;
}

.home-blog .common-blog-post .entry-meta a {
	text-transform: capitalize;
	font-weight: 500;
}

.home-blog .common-blog-post .for-border {
	margin: 0 0 15px;
}

.home-blog .common-blog-post .gallery-slider {
	margin: 0;
}

.home-blog .common-blog-post .video {
	margin: 0;
}

.home-blog .video .video-wrapper {
	padding-bottom: 61%;
}

.home-blog .col-sm-12 img {
	width: 100%;
}

.home-blog .col-sm-12 .text-content {
	text-align: center;
}

.home-blog.blog-var-two .col-sm-12 .text-content {
	text-align: left;
}

.news-shortcode .text-content {
	padding: 25px;
	text-align: left;
}

.news-shortcode .text-content h5 {
	margin: 0 0 10px;
}

.news-shortcode .text-content p {
	margin: 0 0 15px;
}

.news-shortcode .for-border {
	margin: 0 0 15px;
}

.doctors-shortcode .for-border {
	margin: 0 0 15px;
}

.home-blog.blog-var-two .slogan-section {
	text-align: left;
}

.home-blog.blog-var-two .common-blog-post {
	background: transparent;
}

.home-blog.blog-var-two .common-blog-post .text-content {
	padding: 25px 0 10px;
}

.home-blog.blog-var-two .entry-meta {
	margin: 0 0 20px;
	font-style: normal;
}

.home-blog.blog-var-two .for-border {
	display: none;
}
/*------------------------------------------------------*/
/* Gallery Slider
/*------------------------------------------------------*/
.gallery-slider {
	position: relative;
	zoom: 1;
	margin: 0;
	padding: 0;
	margin-bottom: 24px;
}

.gallery-slider.loading {
	min-height: 220px;
	background: #f0f5f7 url('../images/loader.gif') no-repeat center center;
}

.gallery-slider .slides {
	zoom: 1;
}

.gallery-slider .slides img {
	width: 100%;
	display: block;
}

.gallery-slider .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

.gallery-slider .flex-direction-nav a {
	width: 40px;
	height: 40px;
	border-radius: 0;
	margin-top: -20px;
}

.gallery-slider .flex-direction-nav a:before {
	display: none;
}

.gallery-slider .flex-direction-nav .flex-prev {
	background: #67c9e0 url("../images/prev-icon.png") center center no-repeat;
	font-size: 0;
	left: -20px;
	background-size: inherit;
}

.gallery-slider .flex-direction-nav .flex-next {
	background: #67c9e0 url("../images/next-icon.png") center center no-repeat;
	font-size: 0;
	right: -20px;
	background-size: inherit;
}

.gallery-slider:hover .flex-prev {
	opacity: 0.7;
	left: 0px;
}

.gallery-slider:hover .flex-next {
	opacity: 0.7;
	right: 0px;
}

.gallery-slider:hover .flex-next:hover,
.gallery-slider:hover .flex-prev:hover {
	opacity: 1;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	/*.gallery-slider .slides img { margin-left: -1px; }*/
	.gallery-slider .flex-direction-nav .flex-prev {
		margin-left: -0.5px;
	}

	.safari .gallery-slider .slides img {
		margin-left: 0;
	}

	.safari .gallery-slider .flex-direction-nav .flex-prev {
		margin-left: 0;
	}

	.safari .gallery-single #carousel .flex-viewport {
		padding: 8px;
	}
}
/*------------------------------------------------------*/
/*	Home Testimonial Styles
/*------------------------------------------------------*/
.home-testimonial {
	padding: 80px 0;
}

.home-testimonial .flexslider-three {
	position: relative;
	zoom: 1;
	margin: 0;
	padding: 0;
}

.home-testimonial .flexslider-three .slides {
	zoom: 1;
}

.home-testimonial .flexslider-three .slides img {
	margin-bottom: 36px;
	background-color: #eaeaea;
}

.home-testimonial .flexslider-three .slides > li {
	display: none;
	-webkit-backface-visibility: hidden;
}

.home-testimonial .flexslider-three .flex-direction-nav a {
	top: 80px;
	width: 14px;
	height: 17px;
	font-size: 0;
	transition: none;
}

.home-testimonial .flexslider-three .flex-direction-nav a:before {
	display: none;
}

.home-testimonial .flexslider-three .flex-direction-nav .flex-prev {
	left: 39%;
	opacity: 1;
	background: url("../images/prev-icon-two-sprite.png") 0 0 no-repeat;
}

.home-testimonial .flexslider-three .flex-direction-nav .flex-next {
	right: 39%;
	opacity: 1;
	background: url("../images/next-icon-two-sprite.png") 0 0 no-repeat;
}

.home-testimonial .flexslider-three:hover .flex-next:hover {
	background-position: 0px -37px;
}

.home-testimonial .flexslider-three:hover .flex-prev:hover {
	background-position: 0px -37px;
}

.home-testimonial blockquote {
	border: none;
	font-family: serif;
	padding: 0;
	margin: 0 0 50px;
}

.home-testimonial blockquote p {
	font-family: serif;
	font-style: italic;
	font-size: 20px;
	line-height: 32px;
	font-weight: 400;
	color: #6a7177;
}

.home-testimonial .testimonial-footer h3 {
	margin: 0 0 10px;
}

.home-testimonial .testimonial-footer .for-border {
	margin: 0 0 10px;
}

.testimonial-push-down {
	height: 100px;
	background-color: #fff;
}

.home-testimonial.testimonial-var-two .flexslider-three {
	margin-top: -147px;
}
/*------------------------------------------------------*/
/* Home Services styles
/*------------------------------------------------------*/
.home-services {
	padding: 90px 0;
	background-color: #fff;
}

.home-services .slogan-section {
	text-align: left;
}

.tab-main .tab-title {
	border-bottom: 1px solid #dcdee4;
	padding: 15px 10px;
	position: relative;
	z-index: 999;
	cursor: pointer;
}

.tab-main .tab-title h6 {
	font-size: 14px;
	font-weight: 500;
	transition: all 0.2s;
	margin: 0;
}

.tab-main .tab-title:hover h6 {
	margin-left: 5px;
}

.tab-main .tab-title.active {
	background-color: #f0f5f7;
	margin-right: -1px;
}

.tab-main .tab-title.active h6 {
	margin-left: 10px;
	font-weight: 700;
}

.tab-main .tab-content {
	background-color: #f0f5f7;
	border: 1px solid #dcdee4;
	margin-left: -30px;
	min-height: 290px;
	height: auto !important;
	height: 290px;
}

.tab-main .tab-content .content {
	display: none;
	padding: 30px;
}

.tab-main .tab-content h5 {
	margin: 0 0 15px;
}

.tab-main .tab-content p {
	margin-bottom: 28px;
}

.tab-main .tab-title:first-of-type {
	margin-top: 30px;
}

.tab-main .tab-title.active:first-of-type {
	border-top: 1px solid #dcdee4;
}
/*------------------------------------------------------*/
/*	Home Sidebar styles
/*------------------------------------------------------*/
.home-Sidebar .services-widget {
	margin-bottom: 75px;
}

.home-Sidebar .feature-video-widget .slogan-section {
	margin-bottom: 30px;
}

.home-Sidebar .feature-video-widget .read-more {
	margin-top: 30px;
}
/*------------------------------------------------------*/
/* Footer styles
/*------------------------------------------------------*/
#main-footer {
	background-color: #3a3c41;
	padding: 100px 0 30px 0;
	color: #9ba0aa;
	line-height: 24px;
}

#main-footer .widget h3.title {
	margin-bottom: 30px;
	color: #f0f5f7;
	font-size: 18px;
	font-weight: 700;
}

#main-footer .widget p {
	margin-bottom: 25px;
}

#main-footer .widget a {
	color: #b5bac6;
}

#main-footer .widget a:hover {
	color: #cbd1de;
}

#main-footer .widget ul {
	border-color: #4a4c52;
}

#main-footer .widget ul li {
	border-color: #4a4c52;
}

#main-footer .widget .tagcloud a {
	color: #fff;
}

#main-footer .widget label {
	color: #a4a8b2;
}

#main-footer .widget input[type="text"] {
	background-color: #515359;
	border: none;
	padding: 12px 15px;
	margin-bottom: 13px;
	color: #fff;
	overflow: hidden;
}

#main-footer .widget input[type="text"]:focus {
	background-color: #696c74;
}

#main-footer .widget input[type="submit"] {
	border: none;
	background-color: #67c9e0;
	color: #fff;
}

#main-footer .widget input[type="submit"]:hover {
	background-color: #4dbed9;
}

#main-footer .widget .wp-email-capture-widget {
	margin: 20px 0;
}

#main-footer .appoint-widget span {
	border-bottom: 1px solid #4a4c52;
	padding-bottom: 5px;
	margin-bottom: 5px;
	display: block;
}

#main-footer .appoint-widget span:last-child {
	border-bottom: none;
}

#main-footer #wp_email_capture > form > div {
	background: transparent !important;
	color: #e0e0e0;
	margin: 0 0 15px !important;
	width: 100%;
}
/*------------------------------------------------------*/
/* Footer Bottom
/*------------------------------------------------------*/
.footer-bottom {
	margin-top: 60px;
	border-top: 1px solid #4a4c52;
	padding-top: 26px;
}

.footer-bottom p {
	color: #7a7d86;
}

.footer-bottom .footer-social-nav {
	margin: 0;
	padding: 0;
	float: right;
}

.footer-bottom .footer-social-nav li {
	display: inline-block;
	list-style: none;
	margin-left: 10px;
}

.footer-bottom .footer-social-nav li .fa,
.footer-bottom .footer-social-nav li .fab,
.footer-bottom .footer-social-nav li .fal,
.footer-bottom .footer-social-nav li .far,
.footer-bottom .footer-social-nav li .fas {
	font-size: 18px;
	display: block;
	text-align: center;
	line-height: 20px;
	color: #53565c;
}

.footer-bottom .footer-social-nav li .fa:hover,
.footer-bottom .footer-social-nav li .fab:hover,
.footer-bottom .footer-social-nav li .fal:hover,
.footer-bottom .footer-social-nav li .far:hover,
.footer-bottom .footer-social-nav li .fas:hover {
	font-size: 20px;
	color: #fff;
	transition: all 0.2s ease;
	margin-top: -2px;
}

.footer-bottom .footer-social-nav li a {
	width: 20px;
	height: 20px;
	display: inline-block;
	transition: all 0.3s ease;
}

.footer-bottom .footer-social-nav li a:hover {
	text-decoration: none;
}
/*------------------------------------------------------*/
/* Scroll Top
/*------------------------------------------------------*/
#scroll-top {
	display: none;
	position: fixed;
	right: 20px;
	bottom: 20px;
	height: 42px;
	width: 42px;
	z-index: 9999;
	line-height: 3em;
	background: #67c9e0 url("../images/scroll-top.png") center center no-repeat;
	transition: all 0.25s ease-in-out;
	border-radius: 50px;
}

#scroll-top i {
	display: none;
}

#scroll-top:hover {
	background-color: #52abc0;
}
/*------------------------------------------------------*/
/* Doctors Page styles
/*------------------------------------------------------*/
.doctors-posts {
	padding-top: 40px;
	padding-bottom: 60px;
}

.doctors-posts .text-left {
	text-align: left;
}

.doctors-posts .common-doctor {
	background-color: #fff;
	margin-bottom: 40px;
}

.doctors-posts .common-doctor figure {
	overflow: hidden;
}

.doctors-posts .common-doctor h5 {
	margin: 0 0 20px;
}

.doctors-posts .common-doctor .text-content {
	padding: 20px 20px 20px;
}

.doctors-posts .common-doctor .for-border {
	margin-bottom: 20px;
}

.doctors-posts .common-doctor p {
	margin-bottom: 20px;
}

.doctors-posts .social-icon {
	margin: 20px 0 0;
}

.doctors-posts .social-icon ul {
	margin: 0 auto;
	margin-left: -3px;
	text-align: center;
}

.doctors-posts .social-icon ul li {
	display: inline-block;
	list-style: none;
	background-color: #dcdee4;
	float: none;
	margin: 0 5px 5px 0;
}

.doctors-posts .social-icon ul li a {
	width: 36px;
	height: 36px;
	display: inline-block;
}

.doctors-posts .social-icon ul li .fa,
.doctors-posts .social-icon ul li .fab {
	font-size: 18px;
	color: #fff;
	display: block;
	text-align: center;
	line-height: 36px;
}

.doctors-posts .social-icon ul li:hover {
	background-color: #66c9e0;
	transition: all 0.3s ease;
}

.doctors-posts .social-icon ul li.twitter-icon:hover {
	background-color: #55acee;
}

.doctors-posts .social-icon ul li.skype-icon:hover {
	background-color: #009ee5;
}

.doctors-posts .social-icon ul li.facebook-icon:hover {
	background-color: #425e9a;
}

.doctors-posts .social-icon ul li.google-icon:hover {
	background-color: #d63b3b;
}

.doctors-posts .social-icon ul li.youtube-icon:hover {
	background-color: #d02525;
}

.doctors-posts .social-icon ul li.linkedin-icon:hover {
	background-color: #0077b5;
}

.doctors-posts .overlay {
	background: url("/20190104150722/assets/images/doctor-interface-symbol.png") center center no-repeat #67c9e0;
}

div.doctor-departments {
	margin: 0 0 20px;
}

div.doctor-departments a {
	font-size: 14px;
	font-weight: 400;
	color: #67c9e0;
	display: inline-block;
}

div.doctor-departments a:hover {
	color: #52b2c9;
}

.doctors-single {
	margin-bottom: 60px;
}

.doctors-single h1.entry-title {
	font-size: 30px;
	line-height: 36px;
}

.doctors-single .entry-meta {
	background-color: #fff;
	margin-bottom: 40px;
}

.doctors-single .entry-meta figure {
	margin: 0;
	margin-bottom: 25px;
}

.doctors-single .entry-meta h5 {
	margin: 0 25px 10px 25px;
}

.doctors-single .entry-meta div.doctor-departments {
	margin: 0 25px 30px;
}

.doctors-single .doc-schedule {
	background-color: #f8fbfc;
	padding: 30px 25px 10px;
	border-top: 1px solid #eff2f3;
}

.doctors-single .doc-schedule p {
	margin-bottom: 25px;
	color: #3a3c41;
	display: block;
}

.doctors-single .doc-schedule p strong {
	margin-right: 5%;
	color: #3a3c41;
	min-width: 25%;
	display: inline-block;
}

.doctors-single .doc-schedule p span {
	overflow: hidden;
	width: 70%;
}

.doctors-single .social-icon {
	border-top: 1px solid #eff2f3;
	padding: 20px 0;
	margin: 0;
	background-color: #f8fbfc;
}

.doctors-single .social-icon .doc-social-nav {
	margin: 0 25px;
	text-align: left;
}

.doctors-single .side-content {
	border-bottom: 1px solid #dcdee4;
}

.doctors-single .side-content h3 {
	font-weight: 400;
	margin-bottom: 30px;
	line-height: 30px;
}

.doctors-single .side-content h3 span {
	font-size: 24px;
}

.doctors-single .side-content strong {
	font-size: 14px;
	font-weight: 700;
	margin-right: 15px;
	display: inline-block;
	float: left;
	line-height: 24px;
}

.doctors-single .side-content .content strong {
	width: 13%;
}

.doctors-single .side-content .content p {
	width: 85%;
	float: right;
}

.doctors-single .top-area {
	border-bottom: 2px solid #dcdee4;
	margin-bottom: 25px;
}

#related-doctors-title {
	margin: 0 0 30px;
}

.doctors-shortcode .hidden-sm {
	margin-bottom: 50px;
}

.doctors-shortcode .common-doctor {
	background-color: #fff;
}

.doctors-shortcode .common-doctor .text-content {
	padding: 0 15px 0;
}

.doctor-shortcode-post {
	padding: 0;
}

.doctor-shortcode-post .common-doctor {
	margin-bottom: 20px;
}

.doctor-shortcode-post .common-doctor figure {
	margin-bottom: 0;
}

.doctor-shortcode-post.style-vertical {
	margin-top: 5px;
}

@media (min-width: 567px) {
	.doctor-shortcode-post.style-vertical {
		width: 40%;
	}
}

@media (min-width: 768px) {
	.doctor-shortcode-post.style-vertical {
		width: 50%;
	}
}

@media (min-width: 992px) {
	.doctor-shortcode-post.style-vertical {
		width: 41%;
	}
}

@media (min-width: 767px) {
	.doctor-shortcode-post.style-horizontal {
		width: 100%;
	}
}

.style-horizontal .for-border {
	height: 1px;
	background: #dcdee4;
}

@media (min-width: 767px) {
	.style-horizontal .text-content ul {
		text-align: inherit;
		padding-left: 0;
		padding-right: 0;
	}

	.style-horizontal.left .text-content {
		padding-left: 0;
		text-align: left;
	}

	.style-horizontal.right .text-content {
		padding-right: 0;
		text-align: right;
	}
}
/*------------------------------------------------------*/
/* Isotope Filtering
/*------------------------------------------------------*/
.isotope-item {
	z-index: 2;
}

.isotope-hidden.isotope-item {
	pointer-events: none;
	z-index: 1;
}
/*------------------------------------------------------*/
/* Isotope CSS3 transitions
/*------------------------------------------------------*/
.isotope,
.isotope .isotope-item {
	transition-duration: 0.8s;
}

.isotope {
	transition-property: height, width;
}

.isotope .isotope-item {
	transition-property: transform, opacity;
}
/*------------------------------------------------------*/
/* Blog Page Styles
/*------------------------------------------------------*/
.blog-page {
	padding: 50px 0;
}

.blog-page .full-width-contents,
.blog-page .page-contents,
.blog-page .right-contents {
	overflow: hidden;
	border-bottom: 1px solid #dcdee4;
	padding-bottom: 40px;
}

.blog-page .post {
	margin-bottom: 40px;
}

.blog-page .post .left_meta {
	float: left;
	margin-right: 30px;
	border-top: 2px solid #f15b5a;
	background-color: #fff;
}

.blog-page .sticky .right-contents {
	border-bottom: 2px solid #dcdee4;
}

.blog-page .page {
	margin-bottom: 40px;
}

.blog-page .left_meta time {
	padding: 18px 0 20px;
	font-size: 18px;
	color: #3a3c41;
	display: block;
	text-align: center;
	font-weight: 500;
	text-transform: uppercase;
}

.blog-page .left_meta time strong {
	font-size: 30px;
	font-weight: 800;
	line-height: 38px;
	display: block;
}

.blog-page .comments_count {
	display: block;
	padding: 14px 0 18px;
	text-align: center;
	background-color: #e7ecee;
}

.blog-page .comments_count a {
	display: inline-block;
	padding: 0 0 0 25px;
	background: url("../../../common/images/comment-count-icon.png") 0 6px no-repeat;
	font-weight: 700;
	color: #676666;
}

.blog-page .comments_count a:hover {
	background-position: 0 -20px;
}

.blog-page figure {
	margin-bottom: 25px;
	overflow: hidden;
}

.blog-page h3.post-title,
.blog-page h3.entry-title {
	margin: 0 0 15px 0;
	line-height: 32px;
}

.blog-page span.entry-author {
	margin-bottom: 20px;
}

.blog-page span.entry-author {
	display: inline-block;
}

.blog-page .format-quote blockquote.quote {
	font-size: 28px;
	font-weight: 800;
	color: #fff;
	background: url("../images/double-quotes.png") 30px 30px no-repeat #f15b5a;
	padding: 30px 30px 20px 75px;
	margin-bottom: 0;
	border: 0;
	line-height: 36px;
}

.blog-page .format-quote blockquote.quote:before {
	content: normal;
}

.blog-page .format-quote blockquote.quote:after {
	content: normal;
}

.blog-page .format-quote blockquote.quote p {
	border-left: 1px solid #f47c7b;
	padding: 0 0 0 20px;
	margin: 0;
	font-size: 28px;
	line-height: 36px;
	font-weight: 800;
	color: #fff;
}

.blog-page .format-quote blockquote.quote cite,
.blog-page .format-quote blockquote.quote cite a {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	margin: 8px 0;
	display: block;
}

.blog-page .gallery-slider {
	margin-bottom: 25px;
}

.blog-page .gallery-slider.loading {
	min-height: 447px;
	background: #f0f5f7 url('../images/loader.gif') no-repeat center center;
}

.blog-page .flex-direction-nav a {
	width: 60px;
	height: 60px;
	background-size: cover;
	margin-top: -30px;
	border-radius: 0;
}

.blog-page .video {
	margin-bottom: 25px;
}

.blog-page .format-link .link {
	background: url("../images/link-format-bg.png") 30px 25px no-repeat #f15b5a;
	padding: 25px 25px 25px 75px;
}

.blog-page .format-link .link h3.link-title,
.blog-page .format-link .link h3.link-title a {
	font-size: 28px;
	line-height: 36px;
	color: #fff;
	margin: 0 0 15px 0;
}

.blog-page .format-link .link a {
	color: #fff;
}

.blog-page .format-link .link a:hover {
	text-decoration: underline;
}

.blog-page .format-link .link-container {
	padding-left: 20px;
	border-left: 1px solid #f47c7b;
	padding-bottom: 20px;
}

.blog-page .format-audio .audio {
	margin-bottom: 30px;
}

.blog-page .format-audio .audio audio {
	width: 100%;
}

.blog-page blockquote {
	border-left-color: #4dbed9;
}

.blog-page blockquote p {
	margin-bottom: 0 !important;
}

.blog-page p.wp-caption {
	margin: 0 !important;
}

.blog-page .flex-direction-nav {
	margin-bottom: 0;
}

.blog-page .flex-direction-nav li {
	margin: 0;
}

.blog-page .slides,
.blog-page .slides > li {
	margin: 0;
}

.main-contents {
	overflow: hidden;
	border-bottom: 1px solid #dcdee4;
	padding-bottom: 40px;
	margin-bottom: 40px;
}

article.hentry {
	line-height: 24px;
	font-size: 14px;
}

article.hentry p {
	margin-bottom: 25px;
}

article.hentry h1.entry-title {
	margin: 0 0 20px 0;
}

.entry-content {
	margin: 0 0 25px;
}

.entry-content p {
	margin: 0 0 25px;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
	font-weight: 700;
	margin-bottom: 20px;
}

.entry-content h1 {
	font-size: 36px;
	line-height: 42px;
}

.entry-content h2 {
	font-size: 30px;
	line-height: 36px;
}

.entry-content h3 {
	font-size: 24px;
	line-height: 30px;
}

.entry-content h4 {
	font-size: 20px;
	line-height: 28px;
}

.entry-content h5 {
	font-size: 16px;
	line-height: 24px;
}

.entry-content h6 {
	font-size: 14px;
	line-height: 20px;
}

.entry-content blockquote {
	background: #f3f8fa;
	padding: 20px 20px 20px 30px;
	border-left: 5px solid #86d0e4;
	font-family: serif;
	font-style: italic;
	font-size: 16px;
	line-height: 26px;
}

.entry-content blockquote p {
	margin: 0;
	font-family: serif;
	font-style: italic;
	font-size: 16px;
	line-height: 26px;
}

.entry-content td,
.entry-content th {
	padding: 10px;
}

.entry-content table tbody td {
	border: 1px solid #e0e0e0;
}

.entry-content table tbody tr {
	border-top: 1px solid #e0e0e0;
}

.entry-content table tbody tr:first-child {
	border-top: 1px solid #e0e0e0;
}

.entry-content dl {
	line-height: 24px;
}

.entry-content dt {
	color: #3a3c41;
	margin-bottom: 10px;
	line-height: 24px;
}

.entry-content dd {
	margin-left: 10px;
	margin-bottom: 10px;
	line-height: 24px;
}

.entry-content ol,
.entry-content ul {
	list-style: disc;
	padding-left: 25px;
	margin-bottom: 25px;
}

.entry-content ol li,
.entry-content ul li {
	margin: 10px 0;
}

.entry-content ol ol,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ul ul {
	margin-bottom: 10px;
}

.entry-content ol {
	list-style: decimal;
}

.entry-content address {
	line-height: 24px;
}

.entry-content kbd {
	background-color: #67c9e0;
	box-shadow: none;
}

.entry-content pre {
	background-color: #67c9e0;
	color: #fff;
	border: none;
	padding: 15px;
}

.entry-content .gallery img {
	border: none !important;
}

.accordion-content .entry-content {
	margin: 0;
}

.entry-footer p.entry-meta {
	margin: 0;
}

.entry-footer span.entry-tags,
.entry-footer span.entry-categories {
	display: block;
}

.video .video-wrapper {
	padding-bottom: 56.3%;
	position: relative;
	width: 100%;
	height: 0;
}

.video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.page-nav-btns {
	margin: 25px 0;
}

.page-nav-btns a {
	background-color: #67c9e0;
	padding: 5px 15px;
	margin: 0 10px 0 0;
	color: #fff;
	display: inline-block;
}

.page-nav-btns a:hover {
	color: #fff;
	background-color: #f15b5a;
	text-decoration: none;
}

.comments_count .screen-reader-text {
	display: none;
}
/*------------------------------------------------------*/
/* jPlayer Styles
/*------------------------------------------------------*/
.audio-embed {
	margin-bottom: 20px;
}

.jp-jplayer {
	height: 38px;
	background-color: transparent;
	width: 100% !important;
}

.jp-video-container,
.jp-audio-container {
	box-shadow: 0 1px 3px #7f7f7f;
	position: relative;
	width: 100%;
	margin-bottom: 25px;
}

.jp-jplayer-video img {
	margin-bottom: 0px !important;
	width: 100% !important;
	height: auto !important;
	display: block;
}

.jp-jplayer-video {
	overflow: hidden;
}

.jp-jplayer-video video {
	display: block;
	width: 100.2% !important;
}

.jp-video-container {
	margin-bottom: 15px;
}

.jp-audio-container {
	width: 100%;
}

.jp-interface {
	position: relative;
	width: 100%;
	height: 38px;
	background: transparent url('../images/jplayer/jp-bg.png') repeat-x right top;
	z-index: 100;
}

.jp-controls {
	list-style-type: none;
	padding: 0;
	margin: 0 !important;
}

.jp-controls li {
	display: inline;
}

.jp-controls a {
	border-bottom: none;
	position: absolute;
	overflow: hidden;
	text-indent: -9999px;
}

.jp-controls a:hover {
	background-color: transparent;
}

a.jp-play,
a.jp-pause {
	width: 27px;
	height: 27px;
	z-index: 1;
	top: 11px;
	left: 10px;
}

a.jp-play {
	background: url('../images/jplayer/play.png') no-repeat 0 0;
}

a.jp-play:hover {
	background: url('../images/jplayer/play_hover.png') 0 0 no-repeat;
}

a.jp-pause {
	background: url('../images/jplayer/pause.png') 0 0 no-repeat;
	display: none;
}

a.jp-pause:hover {
	background: url('../images/jplayer/pause_hover.png') 0 0 no-repeat;
}

.jp-progress {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	margin: 13px 0;
	padding: 0 100px 0 33px;
	position: absolute;
	height: 10px;
	width: 100%;
}

.jp-seek-bar {
	background: url('../images/jplayer/seek-bar-bg.png') 0 0 repeat-x;
	width: 0px;
	height: 12px;
	cursor: pointer;
	position: relative;
	border-radius: 1px;
}

.jp-play-bar {
	width: 0px;
	height: 12px;
	top: 0px;
	position: relative;
	background-color: #5af1a5;
	border-radius: 1px;
}

.jp-volume-bar-container {
	position: absolute;
	top: 0;
	right: 10px;
	width: 54px;
	height: 10px;
	margin: 10px 0;
}

.jp-volume-bar {
	position: relative;
	overflow: hidden;
	background: url('../images/jplayer/volume-bar-bg.png') 0 0 repeat-x;
	width: 100%;
	height: 12px;
	cursor: pointer;
	top: 3px;
	border-radius: 1px;
}

.jp-volume-bar-value {
	width: 0px;
	height: 12px;
	margin: 0px;
	background-color: #5af1a5;
	border-radius: 1px;
}

a.jp-mute,
a.jp-unmute {
	width: 30px;
	height: 30px;
	z-index: 1;
	top: 11px;
	right: 59px;
}

a.jp-mute {
	background: transparent url('../images/jplayer/volume.png') 0 0 no-repeat;
}

a.jp-mute:hover {
	background: transparent url('../images/jplayer/volume_hover.png') 0 0 no-repeat;
}

a.jp-unmute {
	background: url('../images/jplayer/mute.png') 0 0 no-repeat;
}

a.jp-unmute:hover {
	background: url('../images/jplayer/mute_hover.png') 0 0 no-repeat;
}
/*------------------------------------------------------*/
/* Comments
/*------------------------------------------------------*/
.comments-wrapper {
	margin-left: 115px;
}

#comments-section {
	margin-bottom: 0px;
}

#comments-section > ol > li > .children {
	position: relative;
}

#comments-section .comment {
	list-style: none;
}

#comments-section .commentlist {
	margin: 0 0 40px 0;
	padding: 2px 0px 5px 0px;
	overflow: hidden;
}

.commentlist .children {
	margin: 0;
	padding: 0;
}

.commentlist .children .children {
	margin-left: 8%;
}

#comments-section .children > .comment {
	margin-top: 30px;
}

ol > .comment {
	margin: 30px 0 0;
}

#comments-section article > .comment-wrap {
	background-color: #fff;
	padding: 20px;
	position: relative;
	z-index: 999;
}

#comments-section .bypostauthor article > .comment-wrap {
	background-color: #fdfdfd;
}

#comments-section .children article {
	position: relative;
}

#comments-section .children article .left-border {
	position: absolute;
	background: url("../images/comment-border.png") left center no-repeat;
	width: 100%;
	height: 1px;
	top: 49%;
	left: 0px;
	display: inline-block;
	z-index: 9;
}

#comments-section .children article .vertical-border {
	position: absolute;
	background: url("../images/comment-border2.png") left top repeat-y;
	width: 1px;
	height: 50%;
	bottom: 51%;
	left: 0px;
	display: inline-block;
	z-index: 9;
}

#comments-section .author .comment-reply-link {
	font-size: 11px;
}

.commentlist li.pingback {
	margin-left: 0;
	list-style: none;
	border-bottom: 1px solid #dedede;
	padding: 10px;
}

.commentlist li.pingback p {
	margin: 0;
}

.comment-meta {
	position: relative;
}

.comment-meta .comment-reply-link {
	font-size: 12px;
	color: #fff;
	padding: 5px 10px;
	background-color: #67c9e0;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 999;
	font-weight: 400;
}

.comment-meta .comment-reply-link:hover {
	background-color: #4dbed9;
}

.comment-meta h5.author,
.comment-meta h5.author a {
	font-size: 14px;
	font-weight: 500;
	margin: 0 0 5px 0;
	text-transform: capitalize;
}

.comment-meta time {
	display: block;
	margin: 0 0 5px 0;
	font-size: 12px;
	color: #66c9e0;
	line-height: 18px;
}

.comment-body p {
	margin-bottom: 12px;
}

.commentlist .children .depth-2 .comment-wrap {
	width: 92%;
	margin-left: 8%;
}

.commentlist .children .depth-3 .comment-wrap {
	width: 91%;
	margin-left: 9%;
}

.commentlist .children .depth-4 .comment-wrap {
	width: 90%;
	margin-left: 10%;
}

.commentlist .children .depth-5 .comment-wrap {
	width: 88%;
	margin-left: 12%;
}

#respond p {
	margin-bottom: 20px;
}

#respond input[type="text"],
#respond textarea {
	margin-bottom: 5px;
}

#respond #contact-form p.form-allowed-tags,
#respond #respond p.form-allowed-tags {
	font-size: 12px;
}

#respond #contact-form p.form-allowed-tags code,
#respond #respond p.form-allowed-tags code {
	font-size: 12px;
	display: block;
}

.logged-in-as {
	color: #3a3c41;
	font-size: 16px;
	margin-bottom: 20px;
}

.logged-in-as a {
	font-weight: 700;
}

a.avatar {
	width: 80px;
	height: auto;
	margin: 0 15px 10px 0;
	float: left;
}

a.avatar img {
	width: 100%;
	height: auto;
}

#respond .form-submit {
	margin-top: 30px;
}
/*------------------------------------------------------*/
/* News shortcode styles
/*------------------------------------------------------*/
.news-shortcode {
	text-align: center;
}

.news-shortcode .common-blog-post {
	background-color: #fff;
	line-height: 20px;
}

.news-shortcode .text-content {
	padding: 25px;
	text-align: center;
}

.news-shortcode .gallery-slider {
	margin-bottom: 0px;
}

.news-shortcode .gallery-slider.loading {
	min-height: 447px;
	background: #f0f5f7 url('../images/loader.gif') no-repeat center center;
}

.news-shortcode .flex-direction-nav a {
	width: 60px;
	height: 60px;
	background-size: cover;
	margin-top: -30px;
	border-radius: 0;
}

.news-shortcode .video {
	margin-bottom: 0px;
}

.news-shortcode .video .video-wrapper {
	padding-bottom: 61%;
	position: relative;
	width: 100%;
	height: 0;
}

.news-shortcode .video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.news-shortcode .format-link .link {
	background: url("../images/link-format-bg.png") 30px 25px no-repeat #f15b5a;
	padding: 25px 25px 25px 75px;
}

.news-shortcode .format-link .link h3.link-title,
.news-shortcode .format-link .link h3.link-title a {
	font-size: 28px;
	line-height: 36px;
	color: #fff;
	margin: 0 0 15px 0;
}

.news-shortcode .format-link .link a {
	color: #fff;
}

.news-shortcode .format-link .link a:hover {
	text-decoration: underline;
}

.news-shortcode .format-link .link-container {
	padding-left: 20px;
	border-left: 1px solid #f47c7b;
	padding-bottom: 20px;
}

.news-shortcode .format-audio .audio {
	margin-bottom: 30px;
}

.news-shortcode .format-audio .audio audio {
	width: 100%;
}

.news-shortcode blockquote {
	border-left-color: #4dbed9;
}

.news-shortcode blockquote p {
	margin-bottom: 0 !important;
}

.news-shortcode p.wp-caption {
	margin: 0 !important;
}

.news-shortcode .flex-direction-nav {
	margin-bottom: 0;
}

.news-shortcode .flex-direction-nav li {
	margin: 0;
	list-style: none;
}

.news-shortcode .slides,
.news-shortcode .slides > li {
	margin: 0;
}

.news-shortcode .hidden-sm {
	margin-bottom: 50px;
}

.news-shortcode figure {
	margin-bottom: 0px;
	overflow: hidden;
}
/*------------------------------------------------------*/
/* Sidebar Styles
/*------------------------------------------------------*/
.widget {
	width: 100%;
	margin-bottom: 40px;
}

.widget h3.title {
	margin: 0 0 25px;
	font-size: 18px;
	font-weight: 700;
}

.widget ul {
	padding: 0;
	margin: 0;
	border-top: 1px solid #dde1e3;
}

.widget ul li {
	list-style: none;
	color: #999ca5;
	border-bottom: 1px solid #dde1e3;
	padding: 0 0 10px;
	margin: 10px 0 0;
}

.widget ul.children {
	margin: 10px 0 0 20px;
	border-top: 1px solid #dde1e3;
}

.widget ul.children li:last-child {
	border: none;
	padding: 0;
}

.widget .tagcloud {
	margin-left: -8px;
}

.widget .tagcloud a {
	background-color: #919292;
	color: #fff;
	padding: 7px 14px;
	margin: 0 0 8px 8px;
	display: inline-block;
	font-weight: 400;
	font-size: 14px !important;
}

.widget .tagcloud a:hover {
	background-color: #f15b5a;
	color: #fff;
}

.home-sidebar .widget h3.title {
	font-size: 22px;
}

.home-sidebar .widget .post-video .video-wrapper {
	padding-bottom: 56.3%;
	position: relative;
	width: 100%;
	height: 0;
}

.home-sidebar .widget .post-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.widget_product_search label,
.widget_search label {
	display: none;
}

.widget_product_search input[type="text"],
.widget_search input[type="text"],
.widget_product_search input[type="email"],
.widget_search input[type="email"],
.widget_product_search input[type="search"],
.widget_search input[type="search"] {
	float: left;
	width: 82%;
	border-right: none;
}

.widget_product_search button,
.widget_search button,
.widget_product_search input[type="text"],
.widget_search input[type="text"],
.widget_product_search input[type="email"],
.widget_search input[type="email"],
.widget_product_search input[type="search"],
.widget_search input[type="search"],
.widget_product_search input[type="submit"],
.widget_search input[type="submit"] {
	height: 50px;
	margin-bottom: 0;
}

.widget_product_search button,
.widget_search button,
.widget_product_search input[type="submit"],
.widget_search input[type="submit"] {
	width: 18%;
	padding: 0;
	text-indent: 99999px;
	background: #3a3c41 url('../images/icon-search.svg') no-repeat center center;
	background-size: 20px;
	border-color: transparent;
}

.rtl .widget_product_search button,
.rtl .widget_search button,
.rtl .widget_product_search input[type="text"],
.rtl .widget_search input[type="text"],
.rtl .widget_product_search input[type="email"],
.rtl .widget_search input[type="email"],
.rtl .widget_product_search input[type="search"],
.rtl .widget_search input[type="search"] {
	float: right;
	border-left: none;
	border-right: 1px solid #dcdee4;
}

.rtl .widget_product_search button,
.rtl .widget_search button,
.rtl .widget_product_search input[type="submit"],
.rtl .widget_search input[type="submit"] {
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
/*------------------------------------------------------*/
/* Tabs widget
/*------------------------------------------------------*/
.tabs {
	background-color: #fff;
}

.tabs .tab-head {
	background-color: #f0f5f7;
	border: 1px solid #dde1e3;
	padding: 10px 0;
	text-align: center;
	float: left;
	width: 33.33333333%;
	position: relative;
	cursor: pointer;
}

.tabs .tab-head h6 {
	margin: 5px 0;
	font-weight: 700;
}

.tabs .tab-head.active {
	background: #fff;
	border: 0;
	border-top: 2px solid #f15b5a;
	margin-top: -1px;
}

.tabs .tabs-content {
	display: none;
	clear: both;
	padding: 20px 15px 10px;
}

.tabs .tab-post-listing {
	margin-bottom: 15px;
}

.tabs .tab-post-listing figure {
	width: 64px;
	float: left;
	margin: 0 15px 0 0;
}

.tabs .tab-post-listing figure img {
	transition: all 200ms linear;
	-ms-transform: scale(1);
	transform: scale(1);
}

.tabs .tab-post-listing figure:hover img {
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.tabs .tab-post-listing h6 {
	font-size: 14px;
	font-weight: 700;
	margin: 2px 0 5px;
	line-height: 18px;
}

.tabs .tab-post-listing span {
	font-size: 12px;
	color: #999ca5;
}

.tabs .avatar {
	width: 100%;
}

#wp-calendar {
	width: 100%;
}

#wp-calendar td,
#wp-calendar th {
	text-align: center;
}
/*------------------------------------------------------*/
/* Services page Styles
/*------------------------------------------------------*/
.services-page {
	padding: 40px 0 60px;
}
/* For one column services page */
.one-col-service {
	background-color: #fff;
	margin-bottom: 40px;
}

.one-col-service figure {
	margin: 0 auto 0 -15px;
}
/* For two columns services page */
.two-col-service {
	background-color: #fff;
	margin-bottom: 30px;
}

.two-col-service .contents {
	padding: 25px 25px 25px;
}

.two-col-service .contents h3 {
	margin: 0 0 25px;
}
/* For three columns services page */
.three-col-service {
	background-color: #fff;
	margin-bottom: 30px;
}

.three-col-service .contents {
	padding: 20px;
}

.three-col-service .contents h4 {
	margin: 0 0 20px;
}

.three-col-service .contents p {
	margin: 0 0 20px;
}
/* For four columns services page */
.four-col-service {
	background-color: #fff;
	margin-bottom: 30px;
}

.four-col-service .contents {
	padding: 20px;
}

.four-col-service .contents h4 {
	font-size: 16px;
	margin: 0 0 14px;
	font-weight: 600;
}
/*------------------------------------------------------*/
/* Services Single page Styles
/*------------------------------------------------------*/
.services-single {
	padding: 70px 0;
}

.services-single .flexslider {
	position: relative;
	width: 48%;
	float: left;
	margin-right: 2%;
}

.services-single .flexslider ul {
	margin: 0;
}

.services-single .flexslider .flex-control-nav {
	position: absolute;
	right: 10px;
	bottom: 4px;
	z-index: 999;
	width: auto;
	margin-bottom: 0;
}

.services-single .flexslider .flex-control-nav li {
	margin: 0 6px;
}

.services-single .flexslider .flex-control-nav a {
	background: #e4aaa9;
}

.services-single .flexslider .flex-control-nav .flex-active {
	background: #f15b5a;
}

.services-single figure {
	float: left;
	margin-right: 20px;
	background: #fff;
	overflow: hidden;
}

.services-single h3 {
	margin-bottom: 10px;
}

.services-single h5 {
	font-size: 16px;
	font-weight: 800;
	margin-bottom: 20px;
}

.services-single p {
	margin-bottom: 20px;
	color: #999ca5;
	line-height: 24px;
}

.services-single blockquote {
	margin-bottom: 20px;
	color: #999ca5;
	line-height: 24px;
	border-left: 2px solid #f15b5a;
	padding-top: 0;
	padding-bottom: 0;
}

.services-single ul {
	padding: 0;
	margin-bottom: 20px;
}

.services-single li {
	list-style: none;
	color: #999ca5;
	margin-bottom: 5px;
}

.services-single h1,
.services-single h2,
.services-single ol,
.services-single ul,
.services-single h4,
.services-single h5,
.services-single pre {
	margin-bottom: 30px;
}

.services-single li {
	margin-bottom: 15px;
}

.services-single h6 {
	font-weight: 700;
}

.services-single p a {
	color: #67c9e0;
}

.services-single blockquote {
	border-left-color: #4dbed9;
}

.services-single blockquote p {
	margin-bottom: 0 !important;
}

.services-single p.wp-caption-text {
	margin: 0;
}
/*------------------------------------------------------*/
/* Gallery Single Page Styles
/*------------------------------------------------------*/
.gallery-single-wrapper {
	padding: 40px 0 60px;
}

.gallery-single {
	margin-bottom: 120px;
}

.gallery-single .gallery-single-post {
	position: relative;
}

.gallery-single #carousel {
	position: absolute;
	bottom: -35px;
	display: block;
	width: 90%;
	margin: auto 33px;
	padding: 0 48px;
	border: none;
	background: none;
}

.gallery-single #carousel .flex-viewport {
	padding: 8px 9px 8px 8px;
	background-color: #fff;
}

.gallery-single #carousel .slides {
	background: #fff;
}

.gallery-single #carousel .slides img {
	cursor: pointer;
}

.gallery-single #carousel .slides img:hover {
	opacity: 0.8;
}

.gallery-single #carousel li {
	margin: 0 10px 0 0;
}

.gallery-single #carousel .flex-direction-nav a {
	width: 48px;
	height: 48px;
	position: absolute;
	top: 50%;
	margin-top: -24px;
	z-index: 9999;
	border-radius: 0;
	opacity: 1 !important;
	font-size: 0;
	transition: none;
}

.gallery-single #carousel .flex-direction-nav a:hover {
	background-color: #67c9e0;
	background-position: 19px -31px;
}

.gallery-single #carousel .flex-direction-nav a:before {
	display: none;
}

.gallery-single #carousel .flex-direction-nav .flex-prev {
	background: url("../../../common/images/carousel-prev.png") 19px 19px no-repeat #fff;
	left: 0;
}

.gallery-single #carousel .flex-direction-nav .flex-next {
	background: url("../../../common/images/carousel-next.png") 19px 19px no-repeat #fff;
	right: 0;
}

.gallery-single .side-content {
	background: #fff;
	padding: 15px;
}

.gallery-single .side-content h1.entry-title {
	font-size: 24px;
}

.gallery-single .next-prev-posts {
	margin-bottom: 10px;
}

.gallery-single .next-prev-posts a {
	padding: 15px 17px;
	margin-left: 5px;
	display: inline-block;
	font-size: 18px;
	color: #3a3c41;
	background: #fff;
}

.gallery-single .next-prev-posts a:hover {
	background: #67c9e0;
	color: #fff;
}

.gallery-single .next-prev-posts .close {
	display: none;
}

div.gallery-item-types {
	border: 1px solid #f0f5f7;
	border-left: 0;
	border-right: 0;
	padding: 10px 0;
	margin-bottom: 20px;
}

div.gallery-item-types .fa-tags {
	color: #818693;
	font-size: 16px;
	margin-right: 8px;
}

div.gallery-item-types a {
	font-weight: 400;
	color: #67c9e0;
}

div.gallery-item-types a:hover {
	color: #52b2c9;
}

.related-gallery-items .common {
	margin-bottom: 30px;
	position: relative;
	overflow: hidden;
}

.related-gallery-items .common img {
	width: 100%;
	height: auto;
	transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;
	overflow: hidden;
}

.related-gallery-items .content {
	padding: 10px 20px 20px;
	background-color: #fff;
	text-align: center;
}

.related-gallery-items .gallery-item-types {
	border: none;
	padding: 0;
	margin: 0 0;
}

.overlay {
	background: url("../../../common/images/for-expand-mirror.png") center center no-repeat #67c9e0;
	width: 100%;
	height: 100%;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 999;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	/* IE 9 */
	-webkit-transform: rotate(45deg);
	/* Safari and Chrome */
}

.overlay-effect {
	position: relative;
	overflow: hidden;
}

.overlay-effect:hover .overlay {
	height: 100%;
	transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	/* IE 9 */
	-webkit-transform: rotate(0deg);
	/* Safari and Chrome */
	opacity: 0.8;
}
/*------------------------------------------------------*/
/* Gallery Page Templates Styles
/*------------------------------------------------------*/
.gallery-page {
	padding: 20px 0 60px;
}

.gallery-page #filters {
	margin-bottom: 60px;
}

.gallery-page .common {
	background-color: #fff;
	margin-bottom: 30px;
}

.gallery-page .common .content {
	padding: 15px 20px 25px;
}

.gallery-page .four-col-gallery.common h4 {
	font-size: 18px;
}

.gallery-page .four-col-gallery.common .content {
	padding: 5px 15px 15px;
}

.gallery-page div.gallery-item-types {
	border: none;
	padding: 0;
	margin: 0;
}

.type-service .overlay {
	background: url("../../../common/images/medical-interface-symbol.png") center center no-repeat #67c9e0;
}
/*------------------------------------------------------*/
/* Faq page Styles
/*------------------------------------------------------*/
.faq-page {
	padding: 50px 0;
}
/*------------------------------------------------------*/
/* Toggle Styles
/*------------------------------------------------------*/
.toggle-main .toggle {
	margin-bottom: 30px;
	transition: all 0.5s;
}

.toggle-main .toggle-title {
	background-color: #fff;
	margin: 0;
	height: auto;
	cursor: pointer;
	transition: all 0.5s;
}

.toggle-main .toggle-title h3 {
	padding: 20px 40px 20px 20px;
	margin: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	position: relative;
	/*background: url(../images/faq-open.png) no-repeat 98% 25px;*/
}

.toggle-main .toggle-title i.fa {
	margin-right: 0;
	font-size: 18px;
	position: absolute;
	top: 23px;
	right: 23px;
}

.toggle-main .toggle-content {
	padding: 20px 20px 5px;
	background-color: #fff;
	margin: 0;
	display: none;
}

.toggle-main .toggle.current .toggle-title {
	background-color: #f15b5a;
	border: none;
}

.toggle-main .toggle.current .toggle-title h3 {
	color: #fff;
	/*background: url(../images/faq-close.png) no-repeat 98% 30px;*/
}

.toggle-main .toggle.current .toggle-content {
	border-bottom: 1px solid #dcdee4;
}
/*.accordion-main {
	margin-bottom: 30px;
}

.accordion .accordion-title {
	border-bottom: 1px solid #dcdee4;
	height: 36px;
	margin-bottom: 10px;
	cursor: pointer;
}

.accordion .accordion-title h6 {
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	text-align: left;
}

.accordion .accordion-title span {
	background: url(../images/close-btn.png) 0px 0px no-repeat;
	display: inline-block;
	width: 35px;
	height: 36px;
	vertical-align: bottom;
	float: right;
}

.accordion .accordion-content {
	display: none;
	margin-bottom: 20px;
}

.accordion .accordion-content p {
	margin-bottom: 25px;
}

.accordion .accordion-content .read-more {
	margin: 0;
}

.accordion.current .accordion-title h6 {
	font-size: 14px;
	font-weight: 700;
}

.accordion.current .accordion-title span {
	background: url(../images/open-btn.png) 0px 0px no-repeat;
}*/
/*------------------------------------------------------*/
/* Accordion Styles
/*------------------------------------------------------*/
.accordion-main .accordion {
	margin-bottom: 30px;
	transition: all 0.5s;
}

.accordion-main .accordion-title {
	background-color: #fff;
	margin: 0;
	height: auto;
	cursor: pointer;
	transition: all 0.5s;
}

.accordion-main .accordion-title h3 {
	padding: 20px 40px 20px 20px;
	margin: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
	/* background: url(../images/accordin-open.png) no-repeat 98% 29px; */
	position: relative;
}

.accordion-main .accordion-title i.fa {
	margin-right: 0;
	font-size: 18px;
	position: absolute;
	top: 23px;
	right: 23px;
}

.accordion-main .accordion-content {
	padding: 20px 20px 5px;
	background-color: #fff;
	margin: 0;
	display: none;
}

.accordion-main .accordion.current .accordion-title {
	background-color: #6dc068;
	border: none;
}

.accordion-main .accordion.current .accordion-title h3 {
	color: #fff;
	/* background: url(../images/accordin-close.png) no-repeat 98% 29px; */
}

.accordion-main .accordion.current .accordion-content {
	border-bottom: 1px solid #dcdee4;
}
/*------------------------------------------------------*/
/* Short Codes Styles
/*------------------------------------------------------*/
.arrow-list-one ul {
	list-style: none;
	padding-left: 0;
}

.arrow-list-one ul li {
	padding-left: 25px;
	background: url('../images/arrow-list-one-bg.png') no-repeat 5px 5px;
}

.arrow-list-two ul {
	list-style: none;
	padding-left: 0;
}

.arrow-list-two ul li {
	padding-left: 28px;
	background: url('../images/arrow-list-two-bg.png') no-repeat 0px 3px;
}

.arrow-list-three ul {
	list-style: none;
	padding-left: 0;
}

.arrow-list-three ul li {
	padding-left: 25px;
	background: url('../images/arrow-list-three-bg.png') no-repeat 5px 7px;
}
/*	Tabs
---------------------------------*/
.entry-content .tabs-nav {
	margin: 0 !important;
	padding: 0 !important;
}

.entry-content .tabs-nav li {
	float: left;
	margin: 0;
	list-style: none;
	padding: 14px 10px 10px;
	text-align: center;
	cursor: pointer;
	background: #fafafa;
	border-top: 1px solid #dcdee4;
	border-left: 1px solid #dcdee4;
	border-bottom: none;
}

.entry-content .tabs-nav li:last-child {
	border-right: 1px solid #dcdee4;
}

.entry-content .tabs-nav li:hover,
.entry-content .tabs-nav li.active {
	background: #fff;
}

.entry-content .tabs-nav li.active {
	border-top: 3px solid #6dc068;
	margin-bottom: -2px;
	padding-top: 13px;
}

.entry-content .tabs-container {
	margin: 0 0 25px;
	display: block;
	border: 1px solid #dcdee4;
	padding: 20px 20px;
	background: #fff;
}

.entry-content .tabs-container.vertical {
	width: 80%;
	float: left;
	position: relative;
	left: -1px;
}

.entry-content .tabs-nav.vertical {
	width: 20%;
	float: left;
}

.entry-content .tabs-nav.vertical li {
	float: none;
	border-right: 1px solid #dcdee4;
}

.entry-content .tabs-nav.vertical li:last-child {
	border-bottom: 1px solid #dcdee4;
}

p.message {
	padding: 20px 25px;
	position: relative;
}

p.message .close {
	font-size: 24px;
	line-height: 11px;
	position: absolute;
	top: 10px;
	right: 11px;
}

.bg-info {
	border-left: 3px solid #afd9ee;
}

.bg-warning {
	border-left: 3px solid #f7ecb5;
}

.bg-success {
	border-left: 3px solid #c1e2b3;
}

.bg-danger {
	border-left: 3px solid #e4b9b9;
}

.left-arrow {
	margin: 0;
}

.left-arrow li {
	list-style: circle outside none;
}

.left-arrow:last-of-type {
	margin-bottom: 50px;
}

.dropcap {
	color: #f15b5a;
	font-size: 44px;
	float: left;
	line-height: 44px;
	margin-right: 10px;
}

.dropcap.bg {
	background-color: #f15b5a;
	color: #fff;
	font-size: 26px;
	float: left;
	line-height: 26px;
	margin: 10px 10px 0 0;
	padding: 5px 8px;
	display: inline-block;
}

table {
	border: 1px solid #e0e0e0;
	border-top: 0;
	font-size: 14px;
	background-color: #fff;
	color: #3a3c41;
}

table thead {
	background-color: #66c9e0;
	border: 0;
	font-weight: 700;
	color: #3a3c41;
	padding: 0 1px;
}

table thead tr {
	border: 0;
}

table thead tr th {
	border: 0;
	border-right: 1px solid #5ab1c5;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
}

table thead tr th:first-child {
	border-left: 1px solid #66c9e0;
}

table thead tr th:last-child {
	border-right: 1px solid #66c9e0;
}

table tbody tr:first-child {
	border-top: 0;
}

table tbody td {
	font-size: 14px;
	font-weight: 500;
	color: #3a3c41;
	border: 1px solid #e0e0e0;
}

table tbody th {
	font-size: 14px;
	font-weight: 700;
	color: #3a3c41;
}

table tbody tr {
	border-top: 1px solid #e0e0e0;
}

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
	padding: 16px;
}

.tab-widget {
	background-color: #f3f4f5;
}

.tab-widget .nav-tabs {
	border: 0;
	margin: 0;
}

.tab-widget .nav-tabs > li {
	margin: 0;
	width: 33.33333%;
	height: 55px;
	line-height: 55px;
	display: inline-block;
	cursor: pointer;
}

.tab-widget .nav-tabs > li:last-child {
	border: none;
}

.tab-widget .nav-tabs > li.active,
.tab-widget .nav-tabs > li.active:hover,
.tab-widget .nav-tabs > li.active:focus {
	color: #fff;
	border: none;
	border-radius: 0;
}

.tab-widget .tab-content {
	padding: 15px;
}

.tab-widget .tab-content figure {
	float: left;
	margin-right: 10px;
}

.tab-widget .tab-post {
	border-bottom: 1px solid #d5d6d7;
	padding: 10px;
	margin-bottom: 20px;
}

.tab-widget .tab-post h6 {
	margin: 0;
	font-weight: 500;
	line-height: 18px;
}

.tab-widget .tab-post time {
	font-size: 12px;
	color: #999ca5;
	font-weight: 400;
	margin-bottom: 15px;
	display: inline-block;
}

.tab-widget .tab-post p {
	margin: 0;
}

.tab-widget .tab-post:last-child {
	border-bottom: 0;
	padding: 0px;
	margin-bottom: 0px;
}

.tab-widget .tab-bottom {
	background-color: #d5d6d7;
	width: 100%;
}

.tab-widget .tab-bottom .left,
.tab-widget .tab-bottom .right {
	width: 40px;
	height: 40px;
	display: inline-block;
	cursor: pointer;
}

.tab-widget .tab-bottom .left {
	background: url("../images/tab-nav-left.png") center no-repeat #bbbcbd;
	float: left;
}

.tab-widget .tab-bottom .left:hover {
	background: url("../images/tab-nav-hover.png") center no-repeat #bbbcbd;
}

.tab-widget .tab-bottom .right {
	background: url("../images/tab-nav.png") center no-repeat #bbbcbd;
	float: right;
}

.tab-widget .tab-bottom .right:hover {
	background: url("../images/tab-nav-hover-right.png") center no-repeat #bbbcbd;
}

.tab-var-one .nav-tabs {
	background-color: #ffd543;
}

.tab-var-one .nav-tabs > li {
	border-right: 1px solid #ffc118;
	color: #e89300;
}

.tab-var-one .nav-tabs > li.active,
.tab-var-one .nav-tabs > li.active:hover,
.tab-var-one .nav-tabs > li.active:focus {
	background-color: #ffc118;
}

.tab-var-two .nav-tabs {
	background-color: #f15b5a;
}

.tab-var-two .nav-tabs > li {
	border-right: 1px solid #de4c4a;
	color: #c63836;
}

.tab-var-two .nav-tabs > li.active,
.tab-var-two .nav-tabs > li.active:hover,
.tab-var-two .nav-tabs > li.active:focus {
	background-color: #de4c4a;
}

.tab-var-three .nav-tabs {
	background-color: #66c9e0;
}

.tab-var-three .nav-tabs > li {
	border-right: 1px solid #2f93ab;
	color: #2f93ab;
}

.tab-var-three .nav-tabs > li.active,
.tab-var-three .nav-tabs > li.active:hover,
.tab-var-three .nav-tabs > li.active:focus {
	background-color: #2f93ab;
}

.flexslider-var-one ol,
.flexslider-var-one ul {
	margin: 0;
}

.flexslider-var-one .slides li img {
	box-shadow: inset 0px 0px 34px 0px #000;
}

.flexslider-var-one .flex-control-thumbs {
	position: relative;
	width: 106px;
	float: left;
	margin-top: -40px;
}

.flexslider-var-one .flex-control-thumbs li {
	width: 106px;
	margin-bottom: 20px;
	position: relative;
}

.flexslider-var-one .flex-control-thumbs li.active {
	background: url("../images/active-slide-arrow.png") right center no-repeat;
}

.flexslider-var-one .flex-control-thumbs li.active span {
	position: absolute;
	top: 0;
	left: 0;
	border: 4px solid #66c9e0;
	width: 94%;
	height: 78px;
	display: block;
	z-index: 999;
}

.flexslider-var-one .flex-control-thumbs img {
	width: auto;
	height: auto;
	opacity: 1;
	margin: 0 -4px 0 0;
}

.flexslider-var-one .flex-viewport {
	width: 440px;
	float: right;
}

.flexslider-var-one .flexslider {
	overflow: visible;
}

.flexslider-var-two ol,
.flexslider-var-two ul {
	margin: 0;
}

.flexslider-var-two .slides li img {
	box-shadow: inset 0px 0px 34px 0px #000;
}

.flexslider-var-two .flex-control-thumbs {
	position: relative;
	width: 100%;
	margin: 0;
	margin-left: -15px;
}

.flexslider-var-two .flex-control-thumbs li {
	width: 100px;
	padding-top: 6px;
	margin-left: 15px;
	position: relative;
}

.flexslider-var-two .flex-control-thumbs li.active {
	background: url("../images/active-slide-arrow2.png") top center no-repeat;
}

.flexslider-var-two .flex-control-thumbs li.active span {
	position: absolute;
	top: 6px;
	left: 0;
	border: 4px solid #66c9e0;
	width: 100%;
	height: 78px;
	display: block;
	z-index: 999;
}

.flexslider-var-two .flex-control-thumbs img {
	width: auto;
	height: auto;
	opacity: 1;
	margin: 0 -4px 0 0;
}

.flexslider-var-two .flexslider {
	width: 470px;
	float: right;
	overflow: visible;
}
/*------------------------------------------------------*/
/* Contact page Styles
/*------------------------------------------------------*/
.contact-page {
	padding: 30px 0 60px;
}

.contact-page .contact-form input[type="text"],
.contact-page .contact-form textarea {
	padding: 15px;
}

.contact-page .contact-form input[type="text"],
.contact-page .contact-form textarea {
	width: 100%;
	height: auto;
	border: 1px solid #dcdee4;
	background-color: #fff;
	margin-bottom: 30px;
	padding: 15px 10px;
	color: #3a3c41;
	font-weight: 500;
}

.contact-page .contact-form input[type="submit"] {
	padding: 15px 27px;
	border: none;
	margin: 0 0 0 20px;
	float: right;
}

.contact-page .contact-form input.error,
.contact-page .contact-form textarea.error {
	border: 1px solid #df1e2d;
}

.contact-page .contact-form textarea {
	display: block;
}

.contact-page .contact-form .inspiry-recaptcha-wrapper {
	margin-bottom: 3rem;
}

@media (min-width: 768px) {
	.contact-page .contact-form .inspiry-recaptcha-wrapper {
		margin-bottom: 0;
	}
}

#response-container label.error,
#error-container label.error,
#message-sent label.error {
	font-weight: normal;
	color: #df1e2d;
}

#response-container:not(:empty),
#error-container:not(:empty),
#message-sent:not(:empty) {
	margin-top: 2rem;
}

#response-container label,
#error-container label,
#message-sent label {
	margin-bottom: 0;
}

.map-wrapper-fixed-width {
	margin-top: 50px;
}

.map-wrapper h5 {
	margin-bottom: 20px;
}

#map-canvas {
	width: 100%;
}

#map-canvas img {
	width: auto;
	max-width: none;
}

.default-map {
	height: 355px;
}

.full-width-map {
	height: 420px;
}

.contact-sidebar {
	margin-top: 5rem;
}

@media (min-width: 768px) {
	.contact-sidebar {
		margin-top: 0;
	}
}

.contact-sidebar .timetable-area h5 {
	margin-bottom: 20px;
}

.contact-sidebar .timetable-area .blue {
	color: #10b1d6;
}

.contact-sidebar .timetable-area,
.contact-sidebar .address-area,
.contact-sidebar .social-icon {
	border-bottom: 1px solid #dcdee4;
	padding-bottom: 10px;
	margin-bottom: 30px;
}

.contact-sidebar h2 {
	margin: 0 0 30px;
}

.contact-sidebar address {
	font-size: 14px;
	color: #999ca5;
	font-weight: 400;
	line-height: 26px;
}

.contact-sidebar p {
	color: #999ca5;
}

.contact-sidebar p strong {
	color: #3a3c41;
	font-weight: 500;
	margin-right: 20px;
	min-width: 50px;
	display: inline-block;
	text-align: right;
}

.contact-sidebar .social-icon {
	padding-bottom: 40px;
	margin-left: -5px;
}

.contact-sidebar .social-icon li {
	margin: 0 5px 5px 0;
}

.contact-sidebar .social-icon h5 {
	margin-bottom: 20px;
}

.contact-sidebar .social-icon li.twitter-icon:hover {
	background-color: #2bbfec;
}

.contact-sidebar .social-icon li.skype-icon:hover {
	background-color: #0099e5;
}

.contact-sidebar .social-icon li.facebook-icon:hover {
	background-color: #395b89;
}

.contact-sidebar .social-icon li.linkedin-icon:hover {
	background-color: #1184cd;
}

.contact-sidebar .social-icon li.youtube-icon:hover {
	background-color: #d02525;
}

.contact-sidebar .social-icon li.instagram-icon:hover {
	background-color: #457299;
}

.contact-sidebar .social-icon li.rss-icon:hover {
	background-color: #ec894d;
}

.contact-sidebar .social-icon li.google-icon:hover {
	background-color: #d63b3b;
}
/* big text styles for 404 page */
.jumbotron {
	background: none;
	padding-top: 60px;
	padding-bottom: 100px;
}

.jumbotron h1 {
	font-size: 250px;
	font-weight: 800;
	color: #66c9e0;
}

.jumbotron h1 span {
	color: #999ca5;
}

.jumbotron p {
	line-height: 30px;
}

.appoint-page {
	padding: 40px 0 60px;
}

.appoint-page .entry-content {
	margin-bottom: 40px;
}

.appoint-page .appoint-section {
	background: #3a3c41 url("../images/appoint-form-bg.png") top center repeat-y;
	padding: 10px 70px 70px;
	border-radius: 5px;
}

.appoint-page .appoint-section .top-icon {
	width: 100%;
	text-align: center;
	z-index: 99999;
}

.appoint-page .appoint-section .top-icon img {
	pointer-events: none;
}

.appoint-page form {
	background: url("../images/appoint-form-bottom.png") bottom center repeat-x #fff;
	padding: 60px 20px;
	margin-top: -36px;
}

.appoint-page form input[type="text"],
.appoint-page form input[type="tel"],
.appoint-page form input[type="email"],
.appoint-page form textarea {
	width: 100%;
	border: 0;
	border-bottom: 1px solid #dcdee4;
	padding: 5px;
}

.appoint-page form input[type="text"].error,
.appoint-page form input[type="tel"].error,
.appoint-page form input[type="email"].error,
.appoint-page form textarea.error {
	border-color: red;
}

.appoint-page form input[type="text"],
.appoint-page form input[type="email"] {
	margin-bottom: 40px;
}

.appoint-page form textarea {
	height: 175px;
	display: block;
	margin-bottom: 3rem;
}

.appoint-page form #appointment-loader {
	vertical-align: middle;
	margin: 0 20px;
}

.appoint-page form #message-sent {
	font-weight: 700;
}

.appoint-page .inspiry-recaptcha-wrapper {
	margin-bottom: 2.8rem;
}

#contact-loader {
	float: right;
	margin: 17px 0 0 0;
	display: block;
}

.comment-form-cookies-consent input[type="radio"],
.comment-form-cookies-consent input[type="checkbox"] {
	margin: 0 8px 0 0;
	vertical-align: 2px;
}

.comment-form-cookies-consent label {
	margin-bottom: 0;
	color: inherit;
}

.wpcf7-form > p {
	font-weight: bold;
	letter-spacing: 1px;
}

.wpcf7-form span.wpcf7-form-control-wrap {
	display: inline !important;
}

.wpcf7-form span.wpcf7-not-valid-tip {
	font-weight: normal;
	letter-spacing: 0.5px;
}

.wpcf7-form .wpcf7-form-control {
	margin-top: 5px;
}

.wpcf7-form .wpcf7-list-item-label {
	font-weight: normal;
}

.wpcf7-form input[type="text"].wpcf7-quiz,
.wpcf7-form input[type="number"],
.wpcf7-form input[type="date"] {
	width: auto;
}

.gdpr-checkbox-wrapper {
	margin-bottom: 21px;
}

.gdpr-checkbox-wrapper label {
	color: inherit;
}

.gdpr-checkbox-wrapper .gdpr-checkbox-label {
	display: block;
	font-weight: 700;
	margin-bottom: 5px;
}

.gdpr-checkbox-wrapper .gdpr-text {
	vertical-align: text-top;
}

.gdpr-checkbox-wrapper input[type="checkbox"] {
	margin: 0 5px 0 0;
}
/*------------------------------------------------------*/
/*	WooCommerce Shop - Styles for product with in loop
/*------------------------------------------------------*/
.shop-page {
	padding: 50px 0;
}

.woocommerce-store-notice,
p.demo_store {
	background-color: #f15b5a;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border-color: #dcdee4;
}

.select2-container--default .select2-selection--single {
	border-radius: 0;
	border-color: #dcdee4;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 40px;
	width: 22px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
	background-color: #f15b5a;
	color: #fff;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #66c9e0;
}

.select2-container .select2-selection--single {
	height: 40px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 10px;
	padding-right: 24px;
}

.select2-dropdown {
	border-color: #dcdee4;
	border-radius: 0;
}

.select2-results__option {
	padding-left: 10px;
	padding-right: 10px;
}

.select2-container .select2-choice {
	border: 1px solid #dcdee4;
	border-radius: 0;
	padding: 6px 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li,
.select2-container .select2-selection--single {
	margin: 0;
}

.woocommerce .woocommerce-result-count {
	margin-top: 7px;
}

.woocommerce .woocommerce-ordering {
	margin-bottom: 25px;
}

.woocommerce .woocommerce-ordering select {
	border: 1px solid #dcdee4;
	padding: 3px;
	background-color: #fff;
}

.woocommerce-ordering .select2-container {
	width: 240px !important;
}

.woocommerce ul.products.columns-3 li.product,
.woocommerce-page ul.products.columns-3 li.product {
	background: #fff;
	text-align: center;
	padding-bottom: 20px;
}

@media only screen and (max-width: 560px) {
	.woocommerce ul.products.columns-3 li.product,
	.woocommerce-page ul.products.columns-3 li.product {
		width: 100%;
	}
}

.woocommerce ul.products li.product .price {
	margin-bottom: 0;
	font-size: 14px;
}

.woocommerce ul.products li.product .added {
	display: none;
}

.woocommerce ul.products li.product .price del {
	display: inline-block;
}

.woocommerce ul.products li.product h3 {
	padding-right: 10px;
	padding-left: 10px;
}

.woocommerce ul.products li.product mark {
	color: #3a3c41;
	background-color: transparent;
}

.woocommerce ul.products li.product .star-rating {
	margin-right: auto;
	margin-left: auto;
	line-height: 12px;
	height: 12px;
}

.woocommerce ul.products li.product .read-more,
.woocommerce ul.products li.product .added_to_cart {
	margin-top: 10px;
}

.woocommerce ul.products li.product .added_to_cart {
	line-height: 1;
}

.woocommerce nav.woocommerce-pagination ul,
.woocommerce nav.woocommerce-pagination ul li {
	border: none;
}

.woocommerce nav.woocommerce-pagination ul li a,
.woocommerce nav.woocommerce-pagination ul li span {
	background-color: #fff;
	font-size: 16px;
	line-height: 22px;
	color: #3a3c41;
	margin: 0 0 8px 8px;
	font-weight: 700;
	padding: 10px 20px;
}

.woocommerce nav.woocommerce-pagination ul li a.current,
.woocommerce nav.woocommerce-pagination ul li span.current,
.woocommerce nav.woocommerce-pagination ul li a:hover,
.woocommerce nav.woocommerce-pagination ul li span:hover {
	background-color: #67c9e0;
	color: #fff;
}

.woocommerce span.onsale {
	line-height: 3em;
	background-color: #f15b5a;
	color: #fff;
}

.woocommerce.widget .select2-container {
	width: 100% !important;
}

.select2-container--default .select2-selection--multiple {
	border-radius: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li,
.select2-container--default .select2-selection--multiple .select2-selection__choice {
	margin-right: 5px;
	margin-top: 5px;
	padding: 0 5px;
	border-radius: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	margin-right: 5px;
}

.woocommerce .widget_price_filter .price_label {
	margin-top: 1rem;
}

.woocommerce ul.cart_list,
.woocommerce ul.product_list_widget {
	border-top: none;
}

.woocommerce .widget_shopping_cart .cart_list li,
.woocommerce.widget_shopping_cart .cart_list li {
	border-color: #dcdee4;
	margin: 0;
	padding: 12px 0 10px 30px;
}

.woocommerce .widget_shopping_cart .cart_list li a.remove,
.woocommerce.widget_shopping_cart .cart_list li a.remove {
	top: 10px;
}

.woocommerce ul.product_list_widget li {
	border-color: #dcdee4;
	padding: 12px 0;
}

.woocommerce ul.product_list_widget li:first-child {
	border-top-width: 1px;
	border-top-style: solid;
}

.woocommerce ul.cart_list li img,
.woocommerce ul.product_list_widget li img {
	width: 64px;
	border: 2px solid #fff;
}

.woocommerce ul.cart_list li img:hover,
.woocommerce ul.product_list_widget li img:hover {
	border-color: #dcdee4;
}

.woocommerce .widget_shopping_cart .total,
.woocommerce.widget_shopping_cart .total {
	border-top: 2px solid #dcdee4;
	border-bottom: 2px solid #dcdee4;
	padding: 8px 10px;
	color: #3a3c41;
	margin-bottom: 15px;
}

.woocommerce a.remove {
	line-height: 0.91em;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider .ui-slider-range {
	background-color: #67c9e0;
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
	background-color: #3a3c41;
}

.woocommerce ul.cart_list li .star-rating,
.woocommerce ul.product_list_widget li .star-rating {
	margin: 2px 0;
}

.woocommerce .star-rating:before,
.woocommerce .star-rating span:before {
	color: #67c9e0;
}

.woocommerce ul.cart_list li dl,
.woocommerce ul.product_list_widget li dl {
	border: none;
	padding-left: 0;
}

.woocommerce ul.cart_list li dl dt,
.woocommerce ul.product_list_widget li dl dt,
.woocommerce ul.cart_list li dl dd,
.woocommerce ul.product_list_widget li dl dd {
	padding: 3px 3px 3px 0;
	margin: 0;
	font-size: 12px;
	line-height: 16px;
}

.woocommerce ul.cart_list li dl dd p,
.woocommerce ul.product_list_widget li dl dd p {
	padding: 0;
	margin: 0;
	font-size: 12px;
	line-height: 16px;
}

.woocommerce .widget_layered_nav_filters ul li {
	margin: 0 10px 0 0;
	border: none;
}

.woocommerce #respond input#submit.loading::after,
.woocommerce a.button.loading::after,
.woocommerce button.button.loading::after,
.woocommerce input.button.loading::after {
	top: 1em;
}
/*------------------------------------------------------*/
/*	WooCommerce Shop - Product Single Page
/*------------------------------------------------------*/
.woocommerce div.product div.images {
	margin-bottom: 30px;
}

.woocommerce div.product div.images .flex-control-thumbs {
	margin-top: 30px;
}

.woocommerce div.product form.cart .read-more {
	border: none;
}

.woocommerce div.product form.cart .variations select {
	border: 1px solid #dcdee4;
	padding: 3px;
	background-color: #fff;
}

.woocommerce div.product .woocommerce-tabs ul.tabs {
	background: transparent;
	padding: 0;
	margin: 0;
	border-left: 1px solid #dcdee4;
}

.woocommerce div.product .woocommerce-tabs ul.tabs:before {
	border-color: #dcdee4;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li {
	border-color: #dcdee4;
	border-radius: 0;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li:before,
.woocommerce div.product .woocommerce-tabs ul.tabs li:after {
	display: none;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li a {
	padding: 10px 0;
}

.woocommerce div.product .woocommerce-tabs .panel {
	margin: 0;
	padding: 20px 30px;
	border: 1px solid #dcdee4;
	border-top: none;
	border-radius: 0;
}

.woocommerce #reviews #comments ol.commentlist {
	padding: 0;
}

.woocommerce #reviews #comments ol.commentlist li img.avatar {
	width: 48px;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text {
	margin-left: 64px;
	border-radius: 0;
	border-color: #dcdee4;
}

.woocommerce #reviews h3 {
	margin: 15px 0;
}

.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce a.added_to_cart,
.woocommerce a.button,
.woocommerce-widget-layered-nav-dropdown__submit,
.woocommerce button.button,
.woocommerce button.button.disabled,
.woocommerce input.button,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled],
.woocommerce #respond input#submit,
.woocommerce #respond input[type="submit"] {
	padding: 13px 25px 13px 25px;
	font-weight: 700;
	border-radius: 0;
	border: none;
}

.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce a.added_to_cart:hover,
.woocommerce a.button:hover,
.woocommerce-widget-layered-nav-dropdown__submit:hover,
.woocommerce button.button:hover,
.woocommerce button.button.disabled:hover,
.woocommerce input.button:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover,
.woocommerce #respond input#submit:hover,
.woocommerce #respond input[type="submit"]:hover {
	background-color: #f15b5a;
	color: #fff;
}

.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce a.added_to_cart,
.woocommerce a.button,
.woocommerce-widget-layered-nav-dropdown__submit,
.woocommerce button.button,
.woocommerce button.button.disabled,
.woocommerce input.button,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled],
.woocommerce #respond input#submit,
.woocommerce #respond input[type="submit"] {
	background-color: #3a3c41;
	color: #fff;
}

.woocommerce div.product form.cart .reset_variations {
	margin: 0 10px;
}

.woocommerce ul.products li.product .button {
	background-color: #67c9e0;
	color: #fff;
	margin-bottom: 5px;
}

.woocommerce ul.products li.product .button:hover {
	background-color: #f15b5a;
	color: #fff;
}

.woocommerce .related ul li.product,
.woocommerce .related ul.products li.product,
.woocommerce .upsells.products ul li.product,
.woocommerce .upsells.products ul.products li.product,
.woocommerce-page .related ul li.product,
.woocommerce-page .related ul.products li.product,
.woocommerce-page .upsells.products ul li.product,
.woocommerce-page .upsells.products ul.products li.product {
	width: 22.1%;
	margin-bottom: 20px;
}

.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
	background-color: #fff;
}

.woocommerce .woocommerce-message {
	border-top-color: #f15b5a;
}

.woocommerce div.product .product_title,
.woocommerce div.product .woocommerce-product-rating {
	margin-bottom: 15px;
}

.woocommerce .related.products h3,
.woocommerce .upsells.products h3 {
	margin-top: 30px;
	margin-bottom: 20px;
}

.woocommerce div.product p.price,
.woocommerce div.product span.price {
	margin-bottom: 15px;
}

.woocommerce div.product p.price del,
.woocommerce div.product span.price del {
	display: inline-block;
	margin: 0 10px 0 0;
}

.woocommerce div.product form.cart .select2-container {
	width: 80% !important;
}

.woocommerce div.product form.cart .variations {
	background: transparent;
	margin-bottom: 23px;
}

.woocommerce div.product form.cart .variations tbody tr {
	border: none;
}

.woocommerce div.product form.cart .variations tbody tr td {
	padding: 0 !important;
}

.woocommerce div.product form.cart .variations tbody tr td.label label {
	margin: 10px 0;
}

.woocommerce div.product form.cart .variations_button {
	margin-top: 10px;
}

.woocommerce .product_meta {
	border-top: 1px solid #dcdee4;
}

.woocommerce .product_meta > span {
	display: block;
	padding: 10px 10px;
	border-bottom: 1px solid #dcdee4;
}

.woocommerce table.shop_attributes {
	background: transparent;
	border-collapse: collapse;
}

.woocommerce table.shop_attributes th,
.woocommerce table.shop_attributes td {
	border: 1px solid #dcdee4;
	padding: 10px;
}

.woocommerce table.shop_attributes th p,
.woocommerce table.shop_attributes td p {
	padding: 0;
	margin: 0;
}

.woocommerce #review_form #respond .stars {
	display: inline-block;
	margin: 10px 10px 0;
	vertical-align: middle;
}

.woocommerce #reviews #comment {
	height: 150px;
}
/*------------------------------------------------------*/
/*	WooCommerce Shop - Cart Page
/*------------------------------------------------------*/
.woocommerce table.cart {
	border: none;
	border-collapse: collapse;
	border-radius: 0;
	background: transparent;
}

.woocommerce table.cart thead {
	background: transparent;
	border-bottom: 2px solid #dcdee4;
}

.woocommerce table.cart thead tr th {
	border: none;
	color: inherit;
}

.entry-content table tbody tr:first-child {
	border-color: #dcdee4;
}

.entry-content table tbody tr {
	border-color: #dcdee4;
}

.woocommerce table.shop_table td {
	border: none;
	padding: 10px;
}

.woocommerce table.shop_table td.actions {
	padding-left: 0;
	padding-right: 0;
}

.woocommerce table.shop_table td.woocommerce-orders-table__cell-order-actions a {
	margin: 1px;
	padding: 1px 5px;
	background-color: transparent;
	color: #000;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
	width: 23%;
}

.woocommerce-account .woocommerce-MyAccount-content {
	width: 74%;
}

.woocommerce-cart table.cart img {
	width: 48px;
	border: 3px solid #fff;
}

.woocommerce-cart table.cart td.actions .coupon .input-text {
	width: auto;
	padding: 8.7px 10px;
	margin: 0 5px 0 0;
	border-color: #dcdee4;
}

.woocommerce-cart .cart-collaterals .cart_totals table {
	width: 100%;
	background: transparent;
	border: none;
	border-collapse: collapse;
	border-top: 2px solid #dcdee4;
	border-bottom: 2px solid #dcdee4;
}

.woocommerce-cart .cart-collaterals p {
	margin: 0;
}

.woocommerce-cart .cart-collaterals .shipping-calculator-form p {
	margin: 6px 0;
}

.woocommerce-cart .cart-collaterals .cart_totals table td,
.woocommerce-cart .cart-collaterals .cart_totals table th {
	padding: 10px;
}

.woocommerce-cart .cart-collaterals .cart_totals tr td,
.woocommerce-cart .cart-collaterals .cart_totals tr th {
	border: none;
}

.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt {
	background-color: #67c9e0;
}

.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover {
	background-color: #f15b5a;
}

.woocommerce .cart-collaterals h2,
.woocommerce-page .cart-collaterals h2 {
	font-size: 21px;
}

.woocommerce td.product-name dl.variation dt,
.woocommerce td.product-name dl.variation dd {
	padding: 3px 3px 3px 0;
	margin: 0;
	font-size: 12px;
	line-height: 16px;
}

.woocommerce td.product-name dl.variation dt p,
.woocommerce td.product-name dl.variation dd p {
	padding: 0;
	margin: 0;
	font-size: 12px;
	line-height: 16px;
}

@media (max-width: 768px) {
	#calc_shipping_country_field .select2-container {
		width: 100% !important;
		text-align: left;
	}
}

@media (min-width: 769px) {
	#calc_shipping_country_field .select2-container {
		min-width: 220px;
	}
}

.wcppec-checkout-buttons__separator {
	margin-bottom: 15px;
}

#add_payment_method table.cart .product-thumbnail,
.woocommerce-cart table.cart .product-thumbnail,
.woocommerce-checkout table.cart .product-thumbnail {
	min-width: 68px;
}

.woocommerce-cart table.cart .product-remove,
#add_payment_method table.cart .product-remove,
.woocommerce-checkout table.cart .product-thumbnail {
	min-width: 20px;
}

.woocommerce-MyAccount-navigation ul {
	list-style: none;
	padding-left: 0;
}

.woocommerce-MyAccount-navigation ul li {
	margin-top: 0;
	padding-bottom: 1rem;
	font-size: 1.4rem;
	border-bottom: 1px solid #dde1e3;
}

.woocommerce-MyAccount-navigation ul li .children {
	margin-top: 1rem;
}

.woocommerce-MyAccount-navigation ul li:last-child {
	margin-bottom: 0;
}
/*------------------------------------------------------*/
/*	WooCommerce Shop - Checkout Page
/*------------------------------------------------------*/
.woocommerce .woocommerce-info {
	border-top-color: #66c9e0;
}

.woocommerce .woocommerce-info:before {
	color: #66c9e0;
}

.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message {
	padding: 1em 45px;
}

.woocommerce .woocommerce-error li,
.woocommerce .woocommerce-info li,
.woocommerce .woocommerce-message li {
	margin: 0;
}

.woocommerce form.checkout_coupon,
.woocommerce form.login,
.woocommerce form.register {
	border-radius: 0;
	border-color: #dcdee4;
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
	border-color: #66c9e0;
}

.form-row .inline {
	margin: 0;
}

.woocommerce form .form-row .checkbox,
.woocommerce-page form .form-row .checkbox {
	padding-left: 0;
}

.woocommerce form .form-row .checkbox input[type="checkbox"],
.woocommerce-page form .form-row .checkbox input[type="checkbox"] {
	float: none;
}

.woocommerce-checkout #ship-to-different-address .input-checkbox {
	margin-right: 10px;
	float: none;
}

.woocommerce-checkout #ship-to-different-address label.checkbox {
	display: inline;
	margin: 0;
	padding: 0;
	min-height: 0;
	font-size: 24px;
	line-height: 30px;
	font-weight: bold;
	cursor: pointer;
}

.woocommerce-checkout #ship-to-different-address .input-checkbox {
	position: relative;
	top: -3px;
	margin-left: 10px;
	cursor: pointer;
}

.woocommerce-checkout #order_comments {
	height: 10em;
}

.woocommerce table.shop_table {
	border-radius: 0;
	background: transparent;
	border: none;
	border-collapse: collapse;
}

.woocommerce table.shop_table.customer_details {
	border-top: 2px solid #dcdee4;
	border-bottom: 2px solid #dcdee4;
}

.woocommerce table.shop_table thead {
	background: transparent;
}

.woocommerce table.shop_table thead tr {
	border-bottom: 2px solid #dcdee4;
}

.woocommerce table.shop_table thead th {
	border: none;
	color: inherit;
}

.woocommerce table.shop_table tbody th {
	border-color: #dcdee4;
}

.woocommerce table.shop_table tfoot {
	border-top: 2px solid #dcdee4;
	border-bottom: 2px solid #dcdee4;
}

.woocommerce table.shop_table tfoot td {
	border-color: #dcdee4;
}

.woocommerce table.shop_table tfoot th {
	border-color: #dcdee4;
}

.woocommerce .order_details li {
	color: #3a3c41;
}

.woocommerce .addresses address {
	background: #f3f8fa;
	padding: 20px;
}

.woocommerce-checkout #payment {
	background: #fff;
	border-radius: 0;
}

#add_payment_method #payment div.payment_box,
.woocommerce-cart #payment div.payment_box,
.woocommerce-checkout #payment div.payment_box {
	background-color: #66c9e0;
	color: #fff;
}

#add_payment_method #payment div.payment_box::before,
.woocommerce-cart #payment div.payment_box::before,
.woocommerce-checkout #payment div.payment_box::before {
	border-bottom-color: #66c9e0;
}
/*------------------------------------------------------*/
/*	WooCommerce Shop - Responsive Styles
/*------------------------------------------------------*/
@media (max-width: 768px) {
	.woocommerce-cart table.cart td.actions .coupon .input-text {
		width: 48%;
	}
}

@media (min-width: 480px) and (max-width: 768px) {
	.woocommerce .related ul li.product,
	.woocommerce .related ul.products li.product,
	.woocommerce .upsells.products ul li.product,
	.woocommerce .upsells.products ul.products li.product,
	.woocommerce-page .related ul li.product,
	.woocommerce-page .related ul.products li.product,
	.woocommerce-page .upsells.products ul li.product,
	.woocommerce-page .upsells.products ul.products li.product,
	.woocommerce ul.products li.product,
	.woocommerce-page ul.products li.product {
		width: 48%;
		margin: 0 0 25px;
	}
}

@media (max-width: 479px) {
	.woocommerce .related ul li.product,
	.woocommerce .related ul.products li.product,
	.woocommerce .upsells.products ul li.product,
	.woocommerce .upsells.products ul.products li.product,
	.woocommerce-page .related ul li.product,
	.woocommerce-page .related ul.products li.product,
	.woocommerce-page .upsells.products ul li.product,
	.woocommerce-page .upsells.products ul.products li.product,
	.woocommerce ul.products li.product,
	.woocommerce-page ul.products li.product {
		width: 100%;
		margin: 0 0 25px;
	}

	.woocommerce div.product .woocommerce-tabs ul.tabs li {
		display: block;
		width: 99%;
	}

	.woocommerce .order_details li {
		float: none;
		border: none;
		margin-right: 0;
		padding-right: 0;
	}
}
